import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { Formik, Field, Form } from 'formik';
import { toast } from 'react-toastify';

import { Wrapper, FormGroup } from '../admin-page/admin-page.styles';
import { FormBlock } from './infrastructure.styles';
import { createObject, updateObject } from '../../requests/supervisor.request';
import { getProject } from '../../requests/snp.request';
import ImageGrid from './image-grid.component';
import { IProject } from '../../interfaces/snp.interface';
import 'react-toastify/dist/ReactToastify.css';
import AdditionalFieldsForm from '../formComponents/additional-fields-form.component';
import { options } from '../../constants/snp.constant';
import { checkVal, lowerAndTrim, t } from '../../utils/helpers.utils';
import { useTranslation } from 'react-i18next';
import FieldsComponent from './fields.components';
import FormFooter from './footer.component';
import Tabs from './tabs.component';
import DocumentationForm from '../formComponents/documentation-form.component';
import { ITempFund, ITempVal, documentationKeys } from './education-form.component';

const initialFormData = {
  "id": 0,
  "kato": 0,
  "structureNameRu": "",
  "structureNameKz": "",
  "constructionYear": 0,
  "studentNumber": 0,
  "factInfo": 0,
  "power": 0,
}
export const skipList = [
  "nameEn",
  "requiredFund",
  "files",
  "additionalFields",
  "requirementsRu",
  "requirementsKz",
  "factLocationRu",
  "factLocationKz",
  'images',
  ...documentationKeys
];

export const errMsg: any = {
  nameRu: 'objectName',
  nameKz: 'objectName',
  constructionYear: 'constructionYear',
  factInfo: 'factInfo',
  factCondition: 'factCondition',
  factLocationRu: 'factLocation',
  factLocationKz: 'factLocation',
  repairRequired: 'repair-required',
  power: 'power',
  files: 'files',
  documentationStatus: "documentationStatus",
  workStart: "date",
  workEnd: "date",
  requiredFundSourceRu: "requiredFundSource",
  requiredFundSourceKz: "requiredFundSource",
  requiredFundAmount: "requiredFundAmount",
  subscriberNumber: "subscriberNumber",
  roadLength: "roadLength"
}

const HealthForm = () => {
  const { objectId, kato } = useParams<any>();
  const navigate = useNavigate();
  const { i18n: { language } } = useTranslation();
  const wrapperRef = useRef<HTMLDivElement>(null);

  const [formData, setFormData] = useState<any>(initialFormData);
  const [images, setImages] = useState<any[]>([]);
  const [errors, setErrors] = useState<any>({});
  const [tempVal, setTempVal] = useState<ITempVal>({
    isAdding: false,
    labelKz: '',
    labelRu: '',
    valueKz: '',
    valueRu: '',
    isAddingText: false,
    textKz: '',
    textRu: '',
    labelError: false,
    valueError: false,
    textError: false
  });

  const [tempFund, setTempFund] = useState<ITempFund>({
    labelKz: '',
    labelRu: '',
    value: '',
    isAdding: false,
    labelError: false,
    valueError: false
  })

  const getUpdatedFormData = (objectId: number) => {
    getProject('health', objectId).then(res => {
      setFormData(res.infrastructureEntity)
      setImages(res.images)
    })
  }

  const loadForm = useCallback(() => {
    const snpInfo = JSON.parse(localStorage.getItem('snp') as string)
    const val = kato ? kato : snpInfo.kato;

    if (objectId && objectId !== 'new') {
      getUpdatedFormData(+objectId);
    } else {
      createObject('health', val)
        .then(res => navigate(`/admin/${val}/infrastructure-projects/health/${res.id}`))
    }
  }, [kato, navigate, objectId])

  const handleSave = (values: IProject) => {
    setErrors({})
    setTempVal({ ...tempVal, textError: false, labelError: false, valueError: false })
    setTempFund({ ...tempFund, labelError: false, valueError: false })

    let data: any = {
      ...values,
      additionalFields: { ...formData.additionalFields },
      requiredFund: { ...values.requiredFund, ...formData.requiredFund },
      documentationStatus: values.documentationStatus === "" ? null : values.documentationStatus,
    }

    const keys = Object.keys(data).filter((key: string) => !skipList.includes(key));

    for (const key of keys) {
      if (!checkVal(data[key]) && typeof data[key] !== 'boolean') {
        setErrors((prev: any) => ({ ...prev, [errMsg[key]]: true }))
        toast.error(t(`errors.${errMsg[key]}`, language))
        return
      }
    }

    if ((checkVal(data.factLocationKz) && !checkVal(data.factLocationRu)) || (!checkVal(data.factLocationKz) && checkVal(data.factLocationRu))) {
      setErrors((prev: any) => ({ ...prev, factLocation: true }))
      toast.error(t(`errors.factLocation`, language))
      return
    }

    if (data.constructionYear > 2030 || data.constructionYear <= 0) {
      setErrors((prev: any) => ({ ...prev, constructionYear: true }))
      toast.error(t(`errors.yearRange`, language))
      return
    }

    if (data.repairRequired && (data.repairRequired === 'true' || data.repairRequired === true)) {
      if (!checkVal(data.documentationStatus)) {
        setErrors({ ...errors, documentationStatus: true })
        toast.error(t(`errors.documentationStatus`, language))
        return
      } else if (data.documentationStatus === "HAVE") {
        for (const key of documentationKeys.filter(item => !['requiredFund', 'documentationStatus'].includes(item))) {
          if (!checkVal(data[key])) {
            setErrors((prev: any) => ({ ...prev, [errMsg[key]]: true }))
            toast.error(t(`errors.${errMsg[key]}`, language))
            return
          }
        }
      }
    }

    if (tempVal.isAddingText) {
      if (!checkVal(tempVal.textRu) || !checkVal(tempVal.textKz)) {
        setTempVal({ ...tempVal, textError: true })
        toast.error(t(`errors.additional-value`, language))
        return
      }
      data = {
        ...data,
        additionalFields: addText()
      }
    }

    if (tempVal.isAdding) {
      if (!checkVal(tempVal.labelKz) || !checkVal(tempVal.labelRu)) {
        setTempVal({ ...tempVal, labelError: true })
        toast.error(t(`errors.additional-field`, language))
        return
      }

      if (!checkVal(tempVal.valueKz) || !checkVal(tempVal.valueRu)) {
        setTempVal({ ...tempVal, valueError: true })
        toast.error(t(`errors.additional-value`, language))
        return
      }

      data = {
        ...data,
        additionalFields: addField()
      }
    }

    const add_keys = Object.keys(data.additionalFields).filter((key: string) => !['ru', 'kz'].includes(key))

    for (const key of add_keys) {
      if (key.includes('no-label')) {
        if (!checkVal(data.additionalFields[key].textKz) || !checkVal(data.additionalFields[key].textRu)) {
          setErrors((prev: any) => ({ ...prev, [key]: true }))
          toast.error(t(`errors.additional-value`, language))
          return
        }
      } else {
        if (!checkVal(data.additionalFields[key].valueKz) || !checkVal(data.additionalFields[key].valueRu)) {
          setErrors((prev: any) => ({ ...prev, [key]: true }))
          toast.error(t(`errors.additional-value`, language))
          return
        }
      }
    }

    if (tempFund.isAdding) {
      if (!checkVal(tempFund.labelKz) || !checkVal(tempFund.labelRu)) {
        setTempFund((prev: any) => ({ ...prev, labelError: true }))
        toast.error(t(`errors.additional-field`, language))
        return
      }

      if (!checkVal(tempFund.value)) {
        setTempFund((prev: any) => ({ ...prev, valueError: true }))
        toast.error(t(`errors.additional-value`, language))
        return
      }

      data = {
        ...data,
        requiredFund: addFund()
      }
    }

    const fund_keys = data.requiredFund && Object.keys(data.requiredFund).filter((key: string) => !['ru', 'kz'].includes(key));

    if (fund_keys && data.repairRequired && (data.repairRequired === 'true' || data.repairRequired === true) && data.documentationStatus === 'HAVE') {
      for (const key of fund_keys) {
        if (!checkVal(data.requiredFund[key].value)) {
          setErrors((prev: any) => ({ ...prev, [key]: true }))
          toast.error(t(`errors.additional-value`, language))
          return
        }
      }
    }
    updateObject('health', data)
      .then(res => toast.success(t(`toast.save_success`, language)))
      .catch(err => toast.error(t(`toast.save_error`, language)))
  }

  const addField = () => {
    if (!checkVal(tempVal.labelKz) || !checkVal(tempVal.labelRu)) {
      setTempVal((prev: any) => ({ ...prev, labelError: true }))
      toast.error(t(`errors.additional-field`, language))
      return
    }

    if (!checkVal(tempVal.valueKz) || !checkVal(tempVal.valueRu)) {
      setTempVal((prev: any) => ({ ...prev, valueError: true }))
      toast.error(t(`errors.additional-value`, language))
      return
    }

    const field = {
      ...formData.additionalFields,
      [+new Date()]: {
        labelKz: tempVal.labelKz,
        labelRu: tempVal.labelRu,
        valueKz: tempVal.valueKz,
        valueRu: tempVal.valueRu,
      }
    }

    setFormData({
      ...formData,
      additionalFields: field
    })
    setTempVal({ ...tempVal, labelKz: '', valueKz: '', isAdding: false, labelRu: '', valueRu: '', labelError: false, valueError: false })
    return field
  }

  const addText = () => {
    if (!checkVal(tempVal.textRu) || !checkVal(tempVal.textKz)) {
      setTempVal((prev: any) => ({ ...prev, textError: true }))
      toast.error(t(`errors.additional-value`, language))
      return
    }

    const field = {
      ...formData.additionalFields,
      [`no-label-${+new Date()}`]: { textKz: tempVal.textKz, textRu: tempVal.textRu }
    }

    setFormData({
      ...formData,
      additionalFields: field
    })

    setTempVal({ ...tempVal, textRu: '', textKz: '', isAddingText: false, textError: false })
    return field
  }

  const deleteField = (key: string) => {
    const { [key]: deleted, ...rest } = formData.additionalFields;
    setFormData({ ...formData, additionalFields: rest })
    setTempVal({ ...tempVal, isAdding: false, labelError: false, valueError: false })
    updateObject('HEALTH', { ...formData, additionalFields: rest })
      .then(() => loadForm())
  }

  const addFund = () => {
    if (!checkVal(tempFund.labelKz) || !checkVal(tempFund.labelRu)) {
      setTempFund((prev: any) => ({ ...prev, labelError: true }))
      toast.error(t(`errors.additional-field`, language))
      return
    }

    if (!checkVal(tempFund.value)) {
      setTempFund((prev: any) => ({ ...prev, valueError: true }))
      toast.error(t(`errors.additional-value`, language))
      return
    }

    const { labelKz, labelRu, value } = tempFund;

    const field = { ...formData.requiredFund, [+new Date()]: { labelKz, labelRu, value } }

    const body = {
      ...formData,
      requiredFund: field
    }

    setFormData(body)
    setTempFund({ labelRu: '', labelKz: '', value: '', isAdding: false, labelError: false, valueError: false })

    return field;
  }

  const deleteFund = (key: string) => {
    const { [key]: deleted, ...rest } = formData.requiredFund;

    const body = {
      ...formData,
      requiredFund: rest
    }

    setFormData(body)
    updateObject('HEALTH', body).then(() => {
      loadForm()
    })
  }

  const renderFields = (lang: 'Ru' | 'Kz' = 'Ru', setFieldValue: (fieldName: string, value: string) => void) => {
    return (
      <>
        <FormGroup>
          <label className='required' htmlFor={`name${lang}`}>{t(`form.structureName`, lang)}</label>
          <Field
            id={`name${lang}`}
            name={`name${lang}`}
            placeholder={t(`form.structureName`, lang)}
            onChange={(e: any) => setFieldValue(`name${lang}`, lowerAndTrim(e.target.value))}
            className={errors['objectName'] ? 'error' : ''}
          />
        </FormGroup>

        <FormBlock>
          <div className="grid">
            <FormGroup>
              <label className='required' htmlFor={`constructionYear`}>{t(`form.constructionYear`, lang)}</label>
              <Field
                id={`constructionYear`}
                name={`constructionYear`}
                placeholder={t(`form.constructionYear`, lang)}
                as="input"
                type="number"
                className={errors['constructionYear'] ? 'error' : ''}
              />
            </FormGroup>
            <FormGroup>
              <label className='required' htmlFor={`factInfo`}>{t(`form.fact-power`, lang)}</label>
              <Field
                id={`factInfo`}
                name={`factInfo`}
                placeholder={t(`form.factInfo`, lang)}
                as="input"
                type="number"
                className={errors['factInfo'] ? 'error' : ''}
              />
            </FormGroup>
            <FormGroup>
              <label className='required' htmlFor={`power`}>{t(`form.project-power`, lang)}</label>
              <Field
                id={`power`}
                name={`power`}
                placeholder={t(`form.power`, lang)}
                className={errors['power'] ? 'error' : ''}
              />
            </FormGroup>
            <FormGroup>
              <label className='required' htmlFor={`factCondition`}>{t(`form.factCondition`, lang)}</label>
              <Field
                id={`factCondition`}
                name={`factCondition`}
                as={'select'}
                className={errors['factCondition'] ? 'error' : ''}
              >
                <option value="" selected hidden></option>
                {options.map((option) => <option value={option.value} key={option.value}>{t(option.label.toLowerCase(), lang)}</option>)}
              </Field>
            </FormGroup>
          </div>
        </FormBlock>

        <FormBlock>
          <FormGroup>
            <label htmlFor={`factLocation${lang}`}>{t(`form.factPlace`, lang)}</label>
            <Field
              id={`factLocation${lang}`}
              name={`factLocation${lang}`}
              placeholder={t(`factPlacePlaceholder`, lang)}
              onChange={(e: any) => setFieldValue(`factLocation${lang}`, lowerAndTrim(e.target.value))}
              className={errors['factLocation'] ? 'error' : ''}
            />
          </FormGroup>
        </FormBlock>

        <FormBlock>
          <FormGroup>
            <label className='required' htmlFor={`repairRequired`}>{t(`requirementsOptions.required`, lang)}</label>
            <select
              name="repairRequired"
              id="repairRequired"
              onChange={(e: any) => {
                setFieldValue(`repairRequired`, e.target.value);
                setFormData({ ...formData, repairRequired: e.target.value })
              }}
              defaultValue={""}
              value={formData.repairRequired}
              className={errors[`repair-required`] ? 'error' : ''}
            >
              <option value="" hidden></option>
              {['true', 'false'].map((option) => (
                <option value={option}>{t(`${option}`, lang)} </option>
              ))}
            </select>
          </FormGroup>
        </FormBlock>

        {
          (formData.repairRequired === 'true' || formData.repairRequired === true) && (
            <DocumentationForm
              formData={formData}
              setFormData={setFormData}
              lang={lang}
              setFieldValue={setFieldValue}
              type='health'
              onAdd={addFund}
              tempFund={tempFund}
              setTempFund={setTempFund}
              onDelete={deleteFund}
              errors={errors}
            />
          )
        }

        <AdditionalFieldsForm
          formData={formData}
          setFormData={setFormData}
          lang={lang}
          setFieldValue={setFieldValue}
          onSave={handleSave}
          setTempVal={setTempVal}
          errors={errors}
          tempVal={tempVal}
          onAdd={addField}
          onTextAdd={addText}
          onDelete={deleteField}
          type='health'

        />
      </>
    )
  }


  useEffect(() => {
    loadForm();
  }, [loadForm, objectId])

  return (
    <Wrapper ref={wrapperRef}>
      <Tabs />
      {
        formData.id > 0 && <>
          <Formik
            initialValues={formData}
            onSubmit={(values) => handleSave(values)}
          >
            {({ values, setFieldValue }) => (
              <Form>
                <FieldsComponent renderFields={renderFields} setFieldValue={setFieldValue} />
                <FormFooter type='health' formId={formData.id} />
              </Form>)}
          </Formik>
        </>
      }
      <ImageGrid
        formData={formData}
        loadForm={getUpdatedFormData}
        type={'health'}
        images={images}
        lang={language}
      />
    </Wrapper>
  )
}

export default HealthForm