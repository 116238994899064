import React, { FC, useEffect } from 'react'
import { checkVal, formatDate, getLang } from '../../utils/helpers.utils';
import { useTranslation } from 'react-i18next';
import { getImageUrl } from '../right-menu/education.component';
import { ArrowDown, DownloadIcon, EduMinus, EduPlus } from '../../assets/icons';
import { documentationKeys } from '../insfrastructureProjects/education-form.component';
import { Text } from '../text.component';
import { dateArray } from './documentation.component';
import ImageGrid, { Image } from './infrastructure-projects/image-grid-v2.component';

interface IProps {
  street: any;
  openImageModal: (image: any, images: any, name: string) => void;
  isOpen: boolean,
  handleOpen: (id: any) => void
}
const docList = ['requiredFundSourceRu', 'requiredFundSourceKz', 'requiredFundAmount', 'workEnd', 'workStart', 'requiredFund'];
const defaultList = ['files', 'nameRu', 'id', 'nameKz', 'nameEn', 'images', 'requiredFundSourceRu', 'requiredFundSourceKz', 'requiredFundAmount', 'workEnd', 'workStart', 'requiredFund', ...docList];

const Street: FC<IProps> = ({ street, openImageModal, isOpen, handleOpen }) => {
  const { t } = useTranslation();
  const [skipList, setSkipList] = React.useState<any>(defaultList);

  const handleDownload = (url: string) => {
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', 'фото.png');
    link.target = '_blank';
    document.body.appendChild(link);
    link.click();
    link.remove();
  }

  const transformData = (data: any) => {
    const result: any = [];

    // Group items by fileType and date
    data.reduce((acc: any, { id, fileType, date }: any) => {
      const key = `${fileType}-${date}`;

      if (!acc[key]) {
        acc[key] = { fileType, date, imageIds: [] };
        result.push(acc[key]);
      }

      acc[key].imageIds.push(id);
      return acc;
    }, {});

    return result;
  };

  useEffect(() => {
    if (street.repairRequired === true) {
      setSkipList(defaultList)
    } else {
      setSkipList([...defaultList, ...documentationKeys])
    }
  }, [street.id, street.repairRequired]);

  return (
    <div className="block" key={street.id}>
      <div className="subheading with-icon" onClick={() => handleOpen(street.id)}>
        <div className="education-icon">
          {isOpen ? <EduMinus /> : <EduPlus />}
        </div>
        <Text color="#118f84" fontSize="0.875rem" fontWeight={500}> {street[`name${getLang()}`] || t('street-name')}</Text>
      </div>

      {isOpen && (
        <>
          <div className='items'>
            {Object.keys(street).map((section) => !skipList.includes(section) &&
              <>
                {
                  (street[section] || (!isNaN(street[section]) && street[section] > 0)) && <div key={section}>
                    <div className="item">
                      <Text color="#818689" fontSize="0.875rem" fontWeight={400}>  {t(`form.${section}`).split(',')[0] + ': '}</Text>
                      <Text color="##202224" fontSize="0.875rem" fontWeight={500}>
                        {
                          ['documentationStatus', 'repairRequired', 'factCondition'].includes(section)
                            ? t(street[section]?.toString().toLowerCase())
                            : ['workStart', 'workEnd'].includes(section)
                              ? formatDate(street[section])
                              : street[section]
                        }
                        {section === 'totalStreetLength' && ' км'}
                      </Text>
                    </div>
                  </div>
                }
              </>)
            }

            {
              street.repairRequired && street.documentationStatus === 'HAVE' && <>
                {
                  checkVal(street[`requiredFundSource${getLang()}`]) && <div className="item">
                    <Text color="#818689" fontSize="0.875rem" fontWeight={400}> {t('form.requiredFundSource').split(',')[0] + ': '}</Text>
                    <Text color="##202224" fontSize="0.875rem" fontWeight={500}>{street[`requiredFundSource${getLang()}`]}</Text>
                  </div>
                }
                {
                  checkVal(street.requiredFundAmount) && <div className="item">
                    <Text color="#818689" fontSize="0.875rem" fontWeight={400}> {t('form.requiredFundAmount').split(',')[0] + ': '}</Text>
                    <Text color="##202224" fontSize="0.875rem" fontWeight={500}>
                      {!isNaN(street['requiredFundAmount']) && street['requiredFundAmount'] ? (+street['requiredFundAmount']) : street['requiredFundAmount']}
                      {' '}
                      млн {t('tenge')}
                    </Text>
                  </div>
                }
                {
                  checkVal(street[`requiredFundSource${getLang()}`]) && <div className="item">
                    <Text color="#818689" fontSize="0.875rem" fontWeight={400}> {t('form.requiredFundSource').split(',')[0] + ': '}</Text>
                    <Text color="##202224" fontSize="0.875rem" fontWeight={500}>{street[`requiredFundSource${getLang()}`]}</Text>
                  </div>
                }

                {
                  dateArray.some(key => checkVal(street[key])) && <>
                    {
                      dateArray.map(key => (
                        street[key] && <div className="item" key={key}>
                          <Text color="#818689" fontSize="0.875rem" fontWeight={400}> {t(`form.${key}`)}:</Text>
                          <Text color="##202224" fontSize="0.875rem" fontWeight={500}>{formatDate(street[key])}</Text>
                        </div>
                      ))
                    }
                  </>
                }
              </>
            }
          </div>

          {street.files && Object.keys(street.images).length > 0 && (
            <ImageGrid
              images={transformData(street.files)}
              openImageModal={openImageModal}
              name={street[`name${getLang()}`] || t('street-name')}
            />
          )}
        </>
      )
      }

    </div >
  )
}

export default Street