import styled, { css } from "styled-components";
import { Container } from "../index.style";
import { ZoomableImageContainer } from "../right-menu/index.styles";

const CurrentSnpWrapper = styled.div<{ bgImage: string }>`
  height: 5rem;
  padding: 0.625rem 0;
  display: flex;
  gap: 0.625rem;
  align-items: center;
  margin: 0 0.625rem;
`;

const Image = styled.img< { noShadow?: boolean } > `
  width: 3.75rem;
  height: 3.75rem;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.50);
  ${({ noShadow }) => noShadow && css`
    box-shadow: none;`
  }
  z-index: 20;
`;

const SnpInfo = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`;

const MenuHeader = styled(Container) <{ active: boolean }>`
  height: 3.125rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 0.625rem;

  .left {
    display: flex;
    align-items: center;
  }

  .icon {
    min-width: 3.125rem;
    min-height: 3.125rem;
    max-width: 3.125rem;
    max-height: 3.125rem;
    display: flex;
    justify-content: center;
    align-items: center;
    background: transparent;
    svg {
      cursor: pointer;
    }
  }

  img {
    width: 150px;
    height: auto;
  }
`;


const SwitchContainer = styled.div`
  height: 3.125rem;
  display: flex;
  align-items: center;
  gap: 15px;

  > div {
    cursor: pointer; 
  }
`;

const LeftMenuContainer = styled.div<{ active?: boolean, isShown: boolean, isFocused: boolean }>`
  min-width: 25rem;
  max-width: 25rem;
  background: ${({ active }) => !active ? 'var(--bg)' : 'var(--lightYellow)'};
  box-shadow: 0px 0px 10px 0px #0000004D;
  padding: 0.625rem 0;
  display: flex;
  flex-direction: column;
  gap: 0.625rem;
  z-index: 10;
  position: relative;
  height: 100%;
  overflow: auto;

  ${({ isFocused }) => isFocused && css`
    overflow: visible;
  `}
 
  ::-webkit-scrollbar {
    width: 2px;
    // margin-right: 0.625rem;
  }
  
  ::-webkit-scrollbar-track {
    background-color: transparent; /* You can set a background color here if needed */
    // margin-right: 0.625rem;
  }
  
  ::-webkit-scrollbar-thumb {
    background-color: var(--gray);
    border-radius: 5px; 
    border-top: 15px solid transparent;
    border-bottom: 35px solid transparent;
    background-clip: padding-box;
  }

  .add-info{
    text-align: center;
    font-size: 0.75rem;
  }

  .error {
    text-align: center;
    color: red;
  }

  .scrollable {
    display: flex;
    flex-direction: column;
    gap: 0.625rem;
    max-height: 100%;
    // overflow-y: auto;
    min-height: 22rem;
    padding-right: 0.3125rem;

    ::-webkit-scrollbar {
      width: 2px;
      margin-right: 0.625rem;
    }
  
    ::-webkit-scrollbar-track {
      background-color: transparent; /* You can set a background color here if needed */
      margin-right: 0.625rem;

    }
    
    ::-webkit-scrollbar-thumb {
      background-color: var(--gray);
      border-radius: 5px; 
      border-top: 15px solid transparent;
      border-bottom: 35px solid transparent;
      background-clip: padding-box;
    }
  }

  ${({ isShown }) => !isShown && css`
    display: none;`
  }
`;


const TabContainer = styled.div`
  display: flex;
  gap: 0.625rem;
  width: 20rem;
  padding: 0 0.625rem;
  border-bottom: 1px solid var(--borderGray);
`;

const Tab = styled.div<{ isSelected: boolean }>`
  color: var(--shuttleGray);
  font-size: 0.75rem;
  height: 1.875rem;
  line-height: 1.875rem;
  cursor: pointer;

  ${({ isSelected }) => isSelected && css`
    color: var(--text-color);
    border-bottom: 1px solid var(--text-color);
    font-weight: 700;
  `}
`;

const TabContentContainer = styled(Container)`
  margin-top: -0.625rem;
  border-bottom: 2px solid var(--borderGray);
  border-radius: 0px 0px 10px 10px;
  padding: 0;
`;

const NavBarWrapper = styled.div`
  flex-direction: column;
  display: flex;
  gap: 0.625rem;
  padding: 0.625rem;
  background: #F6F6F6;
`;

const NavIcon = styled.div<{ selected?: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 2.5rem;
  height: 2.5rem;

  svg path {
    stroke: #CCCCCC;
  }
`;

const NavItem = styled.div<{ isSelected: boolean }>`
  display: flex;
  gap: 0.3125rem;
  width: 100%;
  align-items: center;
  cursor: pointer;
  background: #FFFFFF;
  border-radius: 10px;
  height: 2.5rem;

  .flex-1 {
    flex: 1;
  }

  ${({ isSelected }) => isSelected && css`
    background: linear-gradient(90deg, #226AC3 0%, #04A958 100%);

    svg path {
      stroke: #FFFFFF;
    }
  `}
`;

const PassportContainer = styled.div`
  padding: 1.25rem 1.25rem 0.625rem 1.25rem;
  display: flex;
  gap: 1.25rem;
  align-items: flex-start;

  ${ZoomableImageContainer}{
    height: 60vh;
    width: 100%;
    img {
      height: 100%;
    }
  }
`;

const Photo = styled.img`
  width: 5.625rem;
  height: auto;
  border-radius: 5px;
  border: 1px solid #F6F6F6;
  сursor: pointer;
`;

const PersonInfo = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.625rem;
  padding-top: 0.625rem;
  flex: 1;
  width: 100%;

  .group {
    display: flex;
    flex-direction: column;

    .item {
      display: flex;
      gap: 0.625rem;
      padding: 0.75rem 0;
      border-top: 1px solid #F6F6F6;
      align-items: center;

      svg {
        margin-right: 0.3125rem;
      }
    }
  }

  a {
    color: var(--primary-color);
    text-decoration: none;
    font-size: 0.875rem;
    font-weight: 500;
  }
`;

const SearchInputWrapper = styled.div`
  position: relative;
  background: var(--bg-lighter);
  border: 1px solid var(--borderGray);
  border-radius: 0.625rem;
  display: flex;
  height: 3.125rem;
  margin: 0 0.625rem;

  .icon {
    min-width: 3.125rem;
    min-height: 3.125rem;
    max-width: 3.125rem;
    max-height: 3.125rem;
    display: flex;
    justify-content: center;
    align-items: center;
    background: transparent;
  }

  input {
    width: 100%;
    height: 100%;
    font-size: 0.875rem;
    color: var(--text-color);
    border: none;
    border-radius: 0.625rem;
    &:focus {
      outline: none;
    }
    background: var(--bg-lighter);
  }
`;

const SnpContainer = styled.div`
  padding: 0.625rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 0.3125rem;
  max-height: 15.5rem;
  overflow-y: auto;
  overflow-x: hidden;

  ::-webkit-scrollbar {
    width: 2px;
  }
  
  ::-webkit-scrollbar-track {
    background-color: transparent;
  }
  
  ::-webkit-scrollbar-thumb {
    background-color: var(--gray);
    border-radius: 5px; 
    border-top: 15px solid transparent;
    border-bottom: 35px solid transparent;
    background-clip: padding-box;    
  }
`;

const SnpItem = styled.div<{ withIcon?: boolean }>`
  padding: 0.3125rem 0.625rem;
  width: 18.75rem;
  background-color: var(--white);
  border-radius: 10px;
  font-size: 0.75rem;
  line-height: 0.875rem;

  span {
     font-size: 0.75rem;
  }

  b {
    font-size: 0.75rem;
    line-height: 0.875rem;
  }

  img { 
    margin-right: 0.625rem;
  }

  .bold {
    font-weight: 700;
    font-size: 0.75rem;
  }

  strong {
    font-size: 0.75rem;
  }

  ${({ withIcon }) => withIcon && css`
    display: flex;
    align-items: center;
    gap: 0.625rem;
  `}
`;

const LoginContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1.25rem;
  margin-top: 0.625rem;
  min-height: 32vh;
  max-height: 100%;
  .block {
    border-radius: 8px;
    border: 1px solid #E2DEC5;
    padding: 0.9375rem;
    width: 100%;
    display: flex;
    gap: 0.625rem;
    flex-direction: column;

    .heading {
      font-size: 0.875rem;
      display: flex;
      gap: 0.625rem;
      color: var(--text-color);
      align-items: center;
    }

    &:nth-child(2) {
      .heading {
        color: var(--shuttleGray);
      }
    }

    input { 
      border-radius: 10px;
      border: 1px solid #818689;
      background: var(--white);
      padding: 0.9375rem 1.25rem;
      color: #78858F;
      font-size: 0.875rem;
      line-height: 10px;
      height: 2.5rem;
      &:focus {
        outline: none;
      }

      &.input-error {
        color: red;
        border: 1px solid red;
      }
    }

    button {
      display: flex;
      gap: 0.625rem;
      align-items: center;
      justify-content: center;
      height: 3rem;
      border-radius: 0.625rem ;
      border: 1px solid var(--borderGray);
      background: var(--white);
      cursor: pointer;
      text-transform: uppercase;
    }
  }

  .username {
    color: var(--text-color);
    font-size: 1  rem;
  }
`;

const ThemeSwitchContainer = styled.div`
 .checkbox {
  display: none;
}

.switch {
  background-color: #111;
  border-radius: 50px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 5px;
  position: relative;
  height: 39px;
  width: 75px;
  /* Scale the size to your need */
  transform: scale(0.7);
}

.switch .ball {
  background-color: #fff;
  border-radius: 50%;
  position: absolute;
  top: 2px;
  left: 2px;
  height: 33px;
  width: 33px;
  transform: translateX(0px);
  transition: transform 0.2s linear;
}

.checkbox:checked + .switch .ball {
  transform: translateX(36px);
}

.checkbox:checked + .switch  {
  background-color: #2196f3;
}

.icon {
  font-size: 24px;
}
`

const OptionsList = styled.div`
  display: flex;
  background: #fff;
  padding: 20px;
  margin-right: -0.625rem;
  flex-direction: column;
  position: absolute;
  z-index: 100;
  top: 3.125rem;
  width: 690px;
  height: 672px;
  border: 1px solid #E2DEC5;
  padding: 1.25rem;
  gap: 1.25rem;
  border-radius: 5px;
  z-index: 100000;

  .header {
    display: flex;
    justify-content: space-between;

    .close {
      cursor: pointer;
    }
    
    .text {
      display: flex;
      gap: 0.625rem;
    }
  }

  .item {
    display: flex;
    align-items: center;
    height: 40px;
    background: #fff;
    border-bottom: 1px solid #F6F6F6;

    .placemark-icon {
      width: 40px;
      height: 40px;
      display: flex;
      justify-content: center;
      align-items: center;

      &.arrow {
        justify-self: flex-end;
        margin-left: auto;
        svg path {
          stroke: #CCCCCC;
        }
      }
    }
    
    .name {
      display: flex;
      cursor: pointer;
      flex-direction: row;
      justify-content: space-between;    
      padding-left: 10px;  
    }

    &.oblast {
      svg path {
        stroke: #118f84;
      }
    }

    &.district { 
      svg path {
        stroke: #CCCCCC;
      }

      background: #F6F6F6;
      border-bottom: 1px solid #fff;

      &:hover {
        div {
          color: #118f84;
          font-weight: 600; 
        }
      }
    }

    &.hovered {
      background: #F6F6F6;

      .name > div {
        color: #118f84;
        font-weight: 600;
      }

      .arrow {
        svg path {
          stroke: #818689;
        }
      }
    }
  }

  .list {
    display: flex;
    max-height: 600px;
    border: 1px solid #F6F6F6;
    border-radius: 10px;

    .left {
      & > .item:first-child {
        border-top-left-radius: 10px;
      }

      & > .item:last-child {
        border-bottom-left-radius: 10px;
      }
    }

    .right {
      & > .item:last-child {
        border-bottom-right-radius: 10px; 
      }

      & > .item:first-child {
        border-top-right-radius: 10px; 
      }
    }
  }

  .left, 
  .right {
    max-width: 100%; 
    flex: 1;
    overflow-y: auto;
  }

  .right {
    background: #F6F6F6;
  }
`;


export {
  CurrentSnpWrapper,
  Image,
  SnpInfo,
  MenuHeader,
  SwitchContainer,
  LeftMenuContainer,
  TabContainer,
  Tab,
  TabContentContainer,
  NavBarWrapper,
  NavItem,
  NavIcon,
  PassportContainer,
  Photo,
  PersonInfo,
  SearchInputWrapper,
  SnpContainer,
  SnpItem,
  LoginContainer,
  OptionsList,
  ThemeSwitchContainer
}