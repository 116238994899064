import styled from "styled-components";

export const Cards = styled.div`
  display: flex;
  gap: 0.3125rem;
  flex-direction: column;
  justify-content: center;
  height: 100%; 

  .card {
    padding: 0.5rem;
    background: var(--bg-color);
    border: 1px solid var(--primary-color);
    border-radius: 0.625rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .row {
    display: flex;
    flex-direction: column;
    gap: 0.3125rem;
    &__header {
      display: flex;
      justify-content: space-between;
      text-align: center;
      white-space: nowrap;

      > div:first-child {
        text-align: left;
      }
    }

    &__grid {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      gap: 0.3125rem;
      justify-items: right;

      .tal {
        justify-self: left;
      }

      .increase {
        display: flex;
        gap: 0.3125rem;
        align-items: baseline;
      }
    }
  }

  .grid.screening-grid { 
    grid-template-columns: repeat(2, 1fr);
    padding: 0;
    gap: 3px;

    &__item {
      display: flex;
      flex-direction: column;
      gap: 0.25rem;
      align-items: center;
      justify-content: center;
    }

    .value {
      display: flex;
      gap: 0.25rem;
      align-items: baseline;
    }

    .percents {
      display: flex;
      justify-content: space-between;
    }
  }
`