import React from 'react';
import { useNavigate } from "react-router-dom";
import { useTranslation } from 'react-i18next';

import Logo from '../../assets/images/Logo.svg';
import LogoRu from '../../assets/images/Logo-ru.svg'

import { MenuHeader, SwitchContainer } from './index.styles';
import { BurgerIcon, XIcon } from '../../assets/icons';
import { Text } from '../text.component';
import { OBLAST_KATO } from '../../utils/helpers.utils';

interface IProps {
  chosenLanguage: string,
  onChange: (val: 'ru' | 'kz') => void,
  showLogin: boolean,
  setShowLogin: (show: boolean) => void
}

export const languageOptions: { value: 'ru' | 'kz', label: string }[] = [
  { value: 'ru', label: 'РУС' },
  { value: 'kz', label: 'ҚАЗ' },
]

const Header: React.FC<IProps> = ({ chosenLanguage, onChange, showLogin, setShowLogin }) => {
  const { i18n: { language } } = useTranslation();
  const navigate = useNavigate();

  const handleLogoClick = () => {
    navigate(`/${OBLAST_KATO}/snp`);
  }

  return (
    <MenuHeader active={showLogin}>
      <div className="left">
        <div className="icon" onClick={() => setShowLogin(!showLogin)}>
          {showLogin ? < XIcon /> : <BurgerIcon />}
        </div>
        <img src={language === 'ru' ? LogoRu : Logo} alt="eAkimat Logo" onClick={handleLogoClick} />
      </div>

      <SwitchContainer>
        {languageOptions.map((option) => (
          <Text fontSize='0.75rem' fontWeight={400} color={chosenLanguage === option.value ? 'var(--primary-color)' : '#818689'} onClick={() => onChange(option.value)}>
            {option.label}
          </Text>
        ))}
      </SwitchContainer>
    </MenuHeader>
  )
}

export default Header;