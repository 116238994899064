import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next';
import { Formik, Field, Form } from 'formik';
import { ToastContainer, toast } from 'react-toastify';
import { MultiSelect } from "react-multi-select-component";

import { createObject, updateObject } from '../../requests/supervisor.request';
import { getProject } from '../../requests/snp.request';
import { Wrapper, FormGroup } from '../admin-page/admin-page.styles';
import { IProject } from '../../interfaces/snp.interface';

import 'react-toastify/dist/ReactToastify.css';
import ImageGrid from './image-grid.component';
import AdditionalFieldsForm from '../formComponents/additional-fields-form.component';
import DocumentationForm from '../formComponents/documentation-form.component';
import { FormBlock } from './infrastructure.styles';
import { options } from '../../constants/snp.constant';
import { checkVal, t } from '../../utils/helpers.utils';
import FormFooter from './footer.component';
import FieldsComponent from './fields.components';
import Tabs from './tabs.component';
import { ITempFund, ITempVal, documentationKeys } from './education-form.component';
import { errMsg } from './health-form.component';

const initialFormData = {
  "id": 0,
  "kato": 0,
  "communicationProvider": "",
  "subscriberNumber": 0,
  "requirements": {
  },
  "documentationStatus": "",
  "requiredFundSource": "",
  "requiredFundAmount": 0,
  "workStart": "",
  "workEnd": ""
};

const skipList = [
  'nameEn',
  'requirementsRu',
  'requirementsKz',
  'requiredFund',
  'additionalFields',
  'files',
  "subscriberNumber",
  ...documentationKeys
]

const providerOptions = [
  { label: "Altel", value: "Altel" },
  { label: "Kcell", value: "Kcell" },
  { label: "Beeline", value: "Beeline" },
  { label: "Activ", value: "Activ" },
  { label: "Tele2", value: "Tele2" },
  { label: "Казахтелеком", value: "Казахтелеком" },
  { label: "Altel (4G)", value: "Altel (4G)" },
  { label: "Kcell (4G)", value: "Kcell (4G)" },
  { label: "Beeline (4G)", value: "Beeline (4G)" },
  { label: "Activ (4G)", value: "Activ (4G)" },
  { label: "Tele2 (4G)", value: "Tele2 (4G)" },
  { label: "Казахтелеком (4G)", value: "Казахтелеком (4G)" },
  { label: "Другое", value: "other" },
]

const providerOptionsKz = [
  { label: "Altel", value: "Altel" },
  { label: "Kcell", value: "Kcell" },
  { label: "Beeline", value: "Beeline" },
  { label: "Activ", value: "Activ" },
  { label: "Tele2", value: "Tele2" },
  { label: "Казахтелеком", value: "Казахтелеком" },
  { label: "Altel (4G)", value: "Altel (4G)" },
  { label: "Kcell (4G)", value: "Kcell (4G)" },
  { label: "Beeline (4G)", value: "Beeline (4G)" },
  { label: "Activ (4G)", value: "Activ (4G)" },
  { label: "Tele2 (4G)", value: "Tele2 (4G)" },
  { label: "Казахтелеком (4G)", value: "Казахтелеком (4G)" },
  { label: "Басқа", value: "other" },
]

const InternetForm = () => {
  const { i18n: { language } } = useTranslation();
  const { objectId, kato } = useParams<any>();
  const navigate = useNavigate();
  const wrapperRef = useRef<HTMLDivElement>(null);
  const [formData, setFormData] = useState<any>(initialFormData);
  const [images, setImages] = useState<any[]>([]);
  const [selectedProviders, setSelectedProviders] = useState<any[]>([]);
  const [selectedProvidersKz, setSelectedProvidersKz] = useState<any[]>([]);
  const [otherProviders, setOtherProvider] = useState<string>('');
  const [errors, setErrors] = useState<any>({});
  const [tempVal, setTempVal] = useState<ITempVal>({
    isAdding: false,
    labelKz: '',
    labelRu: '',
    valueKz: '',
    valueRu: '',
    isAddingText: false,
    textKz: '',
    textRu: '',
    labelError: false,
    valueError: false,
    textError: false
  });
  const [tempFund, setTempFund] = useState<ITempFund>({
    labelKz: '',
    labelRu: '',
    value: '',
    isAdding: false,
    labelError: false,
    valueError: false
  })

  const handleSave = (values: IProject) => {
    setErrors({});
    setTempVal({ ...tempVal, textError: false, labelError: false, valueError: false })
    setTempFund({ ...tempFund, labelError: false, valueError: false })

    const providersList = selectedProviders.map(item => item.value).filter(item => item !== 'other');

    if (selectedProviders.length === 0) {
      toast.error('Требуется выбрать поставщиков связи');
      setErrors({ ...errors, selectedProviders: true })
      return;
    }

    if (providersList.length === 0 && selectedProviders.map(item => item.value).includes('other') && otherProviders.length === 0) {
      toast.error('Требуется заполнить название поставщика связи');
      setErrors({ ...errors, otherProviders: true })
      return;
    }

    let providers = providersList;

    if (otherProviders.length > 0) {
      providers = [...providersList, otherProviders.split(', ')];
    }

    let data: any = {
      ...values,
      additionalFields: { ...formData.additionalFields },
      requiredFund: { ...values.requiredFund, ...formData.requiredFund },
      communicationProvider: {
        name: providers.join(', ').trim(),
      },
      documentationStatus: values.documentationStatus === "" ? null : values.documentationStatus,
    }

    const keys = Object.keys(data).filter((key: string) => !skipList.includes(key));

    for (const key of keys) {
      if (!checkVal(data[key]) && typeof data[key] !== 'boolean') {
        setErrors((prev: any) => ({ ...prev, [errMsg[key]]: true }))
        toast.error(t(`errors.${errMsg[key]}`, language))
        return
      }
    }

    if (data.repairRequired && (data.repairRequired === 'true' || data.repairRequired === true)) {
      if (!checkVal(data.documentationStatus)) {
        setErrors({ ...errors, documentationStatus: true })
        toast.error(t(`errors.documentationStatus`, language))
        return
      } else if (data.documentationStatus === 'HAVE') {
        for (const key of documentationKeys.filter(item => !['requiredFund', 'documentationStatus'].includes(item))) {
          if (!checkVal(data[key])) {
            setErrors((prev: any) => ({ ...prev, [errMsg[key]]: true }))
            toast.error(t(`errors.${errMsg[key]}`, language))
            return
          }
        }
      }
    }

    if (tempVal.isAddingText) {
      if (!checkVal(tempVal.textRu) || !checkVal(tempVal.textKz)) {
        setTempVal({ ...tempVal, textError: true })
        toast.error(t(`errors.additional-value`, language))
        return
      }
      data = {
        ...data,
        additionalFields: addText()
      }
    }

    if (tempVal.isAdding) {
      if (!checkVal(tempVal.labelKz) || !checkVal(tempVal.labelRu)) {
        setTempVal({ ...tempVal, labelError: true })
        toast.error(t(`errors.additional-field`, language))
        return
      }

      if (!checkVal(tempVal.valueKz) || !checkVal(tempVal.valueRu)) {
        setTempVal({ ...tempVal, valueError: true })
        toast.error(t(`errors.additional-value`, language))
        return
      }

      data = {
        ...data,
        additionalFields: addField()
      }
    }

    const add_keys = Object.keys(data.additionalFields).filter((key: string) => !['ru', 'kz'].includes(key))

    for (const key of add_keys) {
      if (key.includes('no-label')) {
        if (!checkVal(data.additionalFields[key].textKz) || !checkVal(data.additionalFields[key].textRu)) {
          setErrors((prev: any) => ({ ...prev, [key]: true }))
          toast.error(t(`errors.additional-value`, language))
          return
        }
      } else {
        if (!checkVal(data.additionalFields[key].valueKz) || !checkVal(data.additionalFields[key].valueRu)) {
          setErrors((prev: any) => ({ ...prev, [key]: true }))
          toast.error(t(`errors.additional-value`, language))
          return
        }
      }
    }

    if (tempFund.isAdding) {
      if (!checkVal(tempFund.labelKz) || !checkVal(tempFund.labelRu)) {
        setTempFund((prev: any) => ({ ...prev, labelError: true }))
        toast.error(t(`errors.additional-field`, language))
        return
      }

      if (!checkVal(tempFund.value)) {
        setTempFund((prev: any) => ({ ...prev, valueError: true }))
        toast.error(t(`errors.additional-value`, language))
        return
      }

      data = {
        ...data,
        requiredFund: addFund()
      }
    }

    const fund_keys = data.requiredFund && Object.keys(data.requiredFund).filter((key: string) => !['ru', 'kz'].includes(key));


    if (fund_keys && data.repairRequired && (data.repairRequired === 'true' || data.repairRequired === true) && data.documentationStatus === 'HAVE') {
      for (const key of fund_keys) {
        if (!checkVal(data.requiredFund[key].value)) {
          setErrors((prev: any) => ({ ...prev, [key]: true }))
          toast.error(t(`errors.additional-value`, language))
          return
        }
      }
    }

    updateObject('internet-communication', data)
      .then(() => toast.success(t(`toast.save_success`, language)))
      .catch(() => toast.error(t(`toast.save_error`, language)))
  }

  const getUpdatedFormData = (objectId: number) => {
    getProject('internet-communication', objectId).then(res => {
      setFormData(res.infrastructureEntity)

      const providerList = res.infrastructureEntity.communicationProvider?.name?.split(',').map((item: string) => item.trim());
      const preselected = [];
      const custom: string[] = [];

      providerList?.forEach((item: string) => {
        if (providerOptions.map(item => item.label).includes(item)) {
          preselected.push(providerOptions.find(option => option.label === item));
        } else {
          custom.push(item)
        }
      });

      if (custom.length > 0) {
        preselected.push({ label: 'Другое', value: 'other' })
      }

      setSelectedProviders(Array.from(new Set([...preselected.map((item: any) => providerOptions.find(option => option.value === item.value)), ...selectedProviders])));
      setSelectedProvidersKz(Array.from(new Set([...preselected.map((item: any) => providerOptionsKz.find(option => option.value === item.value)), ...selectedProvidersKz])));

      setOtherProvider(custom.join(', '));

      setImages(res.images)
    })
  }

  const loadForm = useCallback(() => {
    const snpInfo = JSON.parse(localStorage.getItem('snp') as string)
    const val = kato ? kato : snpInfo.kato;
    if (objectId && objectId !== 'new') {
      getUpdatedFormData(+objectId)
    } else {
      createObject('internet-communication', val).then(res => {
        navigate(`/admin/${val}/infrastructure-projects/internet-communication/${res.id}`);
      })
    }
  }, [navigate, objectId, kato])

  const handleChangeProvider = (selected: any) => {
    setSelectedProviders(selected.map((item: any) => providerOptions.find(option => option.value === item.value)));
    setSelectedProvidersKz(selected.map((item: any) => providerOptionsKz.find(option => option.value === item.value)));
  }

  const addField = () => {
    if (!checkVal(tempVal.labelKz) || !checkVal(tempVal.labelRu)) {
      setTempVal((prev: any) => ({ ...prev, labelError: true }))
      toast.error(t(`errors.additional-field`, language))
      return
    }

    if (!checkVal(tempVal.valueKz) || !checkVal(tempVal.valueRu)) {
      setTempVal((prev: any) => ({ ...prev, valueError: true }))
      toast.error(t(`errors.additional-value`, language))
      return
    }

    const field = {
      ...formData.additionalFields,
      [+new Date()]: {
        labelKz: tempVal.labelKz,
        labelRu: tempVal.labelRu,
        valueKz: tempVal.valueKz,
        valueRu: tempVal.valueRu,
      }
    }

    setFormData({
      ...formData,
      additionalFields: field
    })
    setTempVal({ ...tempVal, labelKz: '', valueKz: '', isAdding: false, labelRu: '', valueRu: '', labelError: false, valueError: false })
    return field
  }

  const addText = () => {
    if (!checkVal(tempVal.textRu) || !checkVal(tempVal.textKz)) {
      setTempVal((prev: any) => ({ ...prev, textError: true }))
      toast.error(t(`errors.additional-value`, language))
      return
    }

    const field = {
      ...formData.additionalFields,
      [`no-label-${+new Date()}`]: { textKz: tempVal.textKz, textRu: tempVal.textRu }
    }

    setFormData({
      ...formData,
      additionalFields: field
    })

    setTempVal({ ...tempVal, textRu: '', textKz: '', isAddingText: false, textError: false })
    return field
  }

  const deleteField = (key: string) => {
    const { [key]: deleted, ...rest } = formData.additionalFields;
    setFormData({ ...formData, additionalFields: rest })
    updateObject('INTERNET_COMMUNICATION', { ...formData, additionalFields: rest })
      .then(() => loadForm())
  }

  const addFund = () => {
    if (!checkVal(tempFund.labelKz) || !checkVal(tempFund.labelRu)) {
      setTempFund((prev: any) => ({ ...prev, labelError: true }))
      toast.error(t(`errors.additional-field`, language))
      return
    }

    if (!checkVal(tempFund.value)) {
      setTempFund((prev: any) => ({ ...prev, valueError: true }))
      toast.error(t(`errors.additional-value`, language))
      return
    }

    const { labelKz, labelRu, value } = tempFund;

    const field = { ...formData.requiredFund, [+new Date()]: { labelKz, labelRu, value } }

    const body = {
      ...formData,
      requiredFund: field
    }

    setFormData(body)
    setTempFund({ labelRu: '', labelKz: '', value: '', isAdding: false, labelError: false, valueError: false })

    return field;
  }

  const deleteFund = (key: string) => {
    const { [key]: deleted, ...rest } = formData.requiredFund;

    const body = {
      ...formData,
      requiredFund: rest
    }

    setFormData(body)
    updateObject('INTERNET_COMMUNICATION', body).then(() => {
      loadForm()
    })
  }

  const renderFields = (lang: 'Ru' | 'Kz' = 'Ru', setFieldValue: (fieldName: string, value: string) => void) => {
    return <>

      <label htmlFor={`communicationProvider.name`}>{t(`form.communicationProvider`, lang)}</label>
      <MultiSelect
        options={lang === 'Kz' ? providerOptionsKz : providerOptions}
        value={lang === 'Kz' ? selectedProvidersKz : selectedProviders}
        onChange={handleChangeProvider}
        labelledBy="Select"
        hasSelectAll={false}
        disableSearch={true}
        overrideStrings={{ "selectSomeItems": t('select', lang) }}
        className={errors['selectedProviders'] ? 'multi-select error' : 'multi-select'}
      />

      {selectedProviders.map(item => item.value).includes('other') &&
        <FormGroup>
          <div className="building">
            <label htmlFor={`otherProviders`}>{t('form.populationObj.others', lang)} </label>
            <input
              name="otherProviders"
              value={otherProviders}
              onChange={(e) => setOtherProvider(e.target.value)}
              placeholder={t('form.requiredField', lang)}
              className={errors['otherProviders'] ? 'error' : ''}
            />
          </div>
        </FormGroup>
      }

      <FormGroup>
        <div className="building">
          <label htmlFor="subscriberNumber">{t(`form.subscriberNumber`, lang)}</label>
          <Field name="subscriberNumber" as="input" type="number" className={errors['subscriberNumber'] ? 'error' : ''} />
        </div>
      </FormGroup>

      <FormBlock>
        <FormGroup>
          <label className='required' htmlFor={`factCondition`}>{t(`form.factCondition`, lang)}</label>
          <select
            value={formData.factCondition}
            onChange={(e) => {
              setFieldValue(`factCondition`, e.target.value);
              setFormData({ ...formData, factCondition: e.target.value })
            }}
            className={errors[`factCondition`] ? 'error' : ''}
          >
            <option value="" selected hidden></option>
            {
              options.map((option) => (
                <option key={option.value} value={option.value}>{t(option.label.toLowerCase(), lang)}</option>
              ))
            }
          </select>
        </FormGroup>
      </FormBlock>

      <FormBlock>
        <FormGroup>
          <label className='required' htmlFor={`repairRequired`}>{t(`requirementsOptions.required`, lang)}</label>
          <select
            name="repairRequired"
            id="repairRequired"
            onChange={(e: any) => {
              setFieldValue(`repairRequired`, e.target.value);
              setFormData({ ...formData, repairRequired: e.target.value })
            }}
            defaultValue={""}
            value={formData.repairRequired}
            className={errors[`repair-required`] ? 'error' : ''}
          >
            <option value="" hidden></option>
            {['true', 'false'].map((option) => (
              <option value={option}>{t(`${option}`, lang)} </option>
            ))}
          </select>
        </FormGroup>
      </FormBlock>

      {
        (formData.repairRequired === 'true' || formData.repairRequired === true) && (
          <DocumentationForm
            formData={formData}
            setFormData={setFormData}
            lang={lang}
            setFieldValue={setFieldValue}
            type='internet-communication'
            onAdd={addFund}
            tempFund={tempFund}
            setTempFund={setTempFund}
            onDelete={deleteFund}
            errors={errors}
          />
        )
      }

      <AdditionalFieldsForm
        formData={formData}
        setFormData={setFormData}
        lang={lang}
        setFieldValue={setFieldValue}
        onSave={handleSave}
        setTempVal={setTempVal}
        errors={errors}
        tempVal={tempVal}
        onAdd={addField}
        onTextAdd={addText}
        onDelete={deleteField}
        type='internet-communication'
      />
    </>
  }

  useEffect(() => {
    loadForm();
  }, [loadForm, objectId])

  return (
    <Wrapper ref={wrapperRef}>
      <Tabs />
      {
        formData.id > 0 && <>
          <Formik
            initialValues={formData}
            onSubmit={(values) => handleSave(values)}

          >
            {({ values, setFieldValue }) => (
              <Form >
                <FieldsComponent renderFields={renderFields} setFieldValue={setFieldValue} />
                <FormFooter type='internet-communication' formId={formData.id} />
              </Form>
            )}
          </Formik>
        </>
      }
      <ImageGrid
        formData={formData}
        loadForm={getUpdatedFormData}
        type={'internet-communication'}
        images={images}
        lang={language}
      />
      <ToastContainer />
    </Wrapper >
  )
}

export default InternetForm