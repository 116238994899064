import styled, { css } from "styled-components";
import { Container, ModalContent } from "../index.style";
import ArrowRight from '../../assets/icons/arrow-right.svg'

import { Text } from "../text.component";
import { Theme } from "../../contexts/theme.context";

const Heading = styled.div`
  width: 100%;
  padding: 0.625rem 1.25rem;
  border: 1px solid var(--whiteSmoke);
  border-radius: 10px;
  text-transform: uppercase;
`;

const Info = styled.div`
  display: flex;
  gap: 0.625rem;
  height: 100%;

  .content {
    flex: 1;
    position: relative;
  }

  .go-up {
    position: absolute;
    bottom: 0.625rem;
    right: 0.625rem;
    cursor: pointer;
    width: 2.9375rem;
    height: 2.9375rem;
    border-radius: 5px;
    background: #FFF;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0px 0px 10px 0px #00000040;
    path {
      stroke: #118F84;
    }
    &:hover {
      background: #118F84;
      shadow: 0;
      path {
        stroke: #FFF;
      }
    }
  }
`;

const NavBar = styled(Container)`
  flex-direction: column;
  gap: 0.625rem;
  width: 10.5rem;
  height: 100%;
`;

const NavItem = styled.div<{ selected: boolean }>`
  width: 9.25rem;
  border-radius: 3.125rem;
  border: 1px solid var(--borderGray);
  padding: 0.375rem 0.9375rem;
  cursor: pointer;
  background: var(--white);

  p {
    max-width: 7rem;
    white-space: break-all;
    font-size: 0.75rem;
    color: var(--shuttleGray);
  }

  ${({ selected }) => selected && css`
    background: linear-gradient(180deg, #2366CA 0%, #02AC51 100%);
    position: relative;
    
    p {
      color: var(--white);
      font-weight: 700;
    }
    
    &:after{
      content: "";
      background-image: url(${ArrowRight});
      background-size: 0.25rem 0.5rem;
      position: absolute;
      right: 0.9375rem;
      width: 0.25rem;
      height: 0.5rem;
      top: 50%;
      transform: translateY(-50%);  
    }
  `}
`;

const FloatingTabsContainer = styled.div`
  margin-left: 0.625rem;
  margin-top: 0.625rem;
  padding: 0.625rem;
  border-radius: 10px;
  background: var(--whiteSmoke);
  display: flex;
  gap: 0.625rem;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.30);
  position: relative;
  z-index: 100000;
  height: fit-content;
`;

const TabButton = styled.button<{ selected: boolean }>`
  border-radius: 30px;
  border: 1px solid var(--borderGray);
  padding: 0px 0.9375rem;
  cursor: pointer;
  height: 1.6875rem;
  text-transform: uppercase;
  font-size: 0.625rem;
  background: var(--bg-white);
  color: var(--text-color);
  white-space: nowrap;

  ${({ selected }) => selected && css`
    background: linear-gradient(180deg, #2366CA 0%, #02AC51 100%);
    color: var(--white);
  `}
`;

const HeaderContainer = styled.div`
  width: 100%;
  height: 2.9375rem;
  display: flex;
  gap: 0.625rem;
  position: relative;

  .toggle {
    position: absolute;
    left: -1.25rem;
    top: 50%;
    cursor: pointer;
    transform: translateY(-50%);
    width: 1.5rem;
    height: 1.5rem;
    border-radius: 4px;
    background: var(--bg-lighter);
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.30);
    border: 1px solid var(--borderGray);
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

const Item = styled(Container) <{ full?: boolean, withScroll?: boolean }>`
  gap: 0.625rem;
  max-width: calc(100% - 3rem);
  overflow-x: auto; 
  overflow-y: hidden;

  ${({ full }) => full && css`
    flex: 1;
  `}

  ${({ withScroll }) => withScroll && css`
    &::-webkit-scrollbar {
      height: 2px;
    }

    &::-webkit-scrollbar-track {
      background-color: var(--whiteSmoke); 
    }

    &::-webkit-scrollbar-thumb {
      background-color: #98a3a9;
      border-radius: 5px;
      border-top: 0;
      border-bottom: 0;
    `
  }

  img {
    cursor: pointer;
  }
`;

const RightMenuContainer = styled.div<{ isFull: boolean }>`
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.30);
  background: #F6F6F6;
  flex: 1;
  display: flex;
  flex-direction: column;
  z-index: 5;
  position: absolute;
  max-height: 100%;
  overflow-y: auto;
  overflow-x: auto;
  height: min-content;
  left: 25rem;
  width: calc(100vw - 25rem);

  @media (max-width: 1365px) {
    padding-bottom: 0.3125rem;
  }
 

  .grid {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 0.625rem;
    max-width: 100%;
    height: 100%;
    padding-right: 0.625rem;
    background: #f6f6f6;
    padding: 1.25rem 1.25rem 0.625rem 1.25rem;
  }
  
  ${ModalContent}{
    left: 60%;
  }

  iframe {
    flex: 1;
  }

  .close-button {
    position: absolute;
    top: 0.625rem;
    right: 0.625rem;
    cursor: pointer;

    svg path {
      fill: var(--text-color);
      stroke: var(--text-color);
    }
  }

  ${({ isFull }) => isFull && css`
    margin-left: 0!important;
    width: calc(100vw - 1.25rem);
    left: 0;
    border-radius: 0.625rem;
  `}
`;

const EducationContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.25rem;
  max-height: 80vh;
  overflow-y: auto;
  padding: 1.25rem;
  background: #f6f6f6;
  white-space: nowrap;

  ::-webkit-scrollbar {
    width: 2px;
    margin-right: 0.625rem;
  }
  
  ::-webkit-scrollbar-track {
    background-color: transparent; /* You can set a background color here if needed */
    margin-right: 0.625rem;

  }
  
  ::-webkit-scrollbar-thumb {
    background-color: var(--gray);
    border-radius: 5px; 
    border-top: 15px solid transparent;
    border-bottom: 35px solid transparent;
    background-clip: padding-box;
  }  
`;

const EducationItem = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 0.625rem;
  border-left: 3px solid #118f84;
  width: 100%;
  background: #fff;
  padding-top: 0.625rem;
  padding-bottom: 0.625rem;

  .education-icon {
    width: 2.5rem;
    height: 2.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .heading,
  .subheading {
    color: var(--primary-color);
    font-size: 14px;
    width: 100%;
  }

  .with-icon{
    display: flex;
    align-items: center;
    cursor: pointer;

    path {
      stroke: var(--primary-color);
    }
  }

  .heading {
    text-transform: uppercase;
    align-items: center;
    padding-right: 1.25rem;
    height: 2.5rem;

    .year {
      justify-self: flex-end;
      margin-left: auto;
      text-transform: none;
      display: flex;
      gap: 5px;
    }
  }

  .items {
    display: flex;
    width: 100%;
    gap: 0.625rem;
    flex-wrap: wrap;

    .item{
      align-self: flex-start;
    }
  }

  .item {
    padding: 0 0.625rem;
    height: 30px;
    border-radius: 5px;
    display: flex;
    gap: 5px;
    border: 1px solid #DBDCE0;
    align-items: center;
   
    > div {
      display: flex;
      gap: 0.3125rem;
      font-size: 0.875rem;
    }

    b {
      font-size: 0.875rem;
    }
  }

  .divider {
    width: 100%;
    height: 1px;
    background: var(--borderGray);
  }

  .block {
    display: flex;
    flex-direction: column;
    gap: 0.375rem;
    border-radius: 0.625rem;

    ul {
      padding-left: 1.25rem;

      li{
        color: var(--shuttleGray);
        font-size: 14px;
        font-weight: 400;
        margin-bottom: 0.375rem;
        b {
          font-size: 14px;
        }
      }
    }
  }

  .subheading{
    padding-bottom: 0.375rem;
    font-weight: 500;
    width: 100%;
    border-bottom: 1px solid var(--borderGray);
  }

  .text-block {
    display: flex;
    flex-direction: column;
    gap: 0.375rem;
  }

  .text {
    font-size: 14px;
    color: var(--shuttleGray);
  }

  .images {
    display: flex;
    gap: 0.625rem;
    flex-wrap: wrap;
    img{
      max-width: 14.375rem;
      max-height: 8.1875rem;
      border-radius: 0.625rem;
      cursor: pointer;
    }

    .footer{ 
      display: flex;
      justify-content: space-between;
      width: 100%;

      button { 
        all: unset;
        cursor: pointer;
      }
    }
  }

  .images__item{
    display: flex;
    flex-direction: column;
    gap: 0.625rem;
    align-items: flex-end;
  }

  .images__date{
    font-size: 0.75rem;
    font-style: italic;
  }

  .memorandum-year {
    padding-top: 0.625rem;
  }

  .street-list-heading {
    color: var(--primary-color);
    line-height: 1;
  }

  .body {
    padding: 0.625rem 1.25rem;
    display: flex;
    flex-direction: column;
    gap: 1.25rem;
  }

  .street-list {
    padding-left: 1.25rem;
  }
`;

const ZoomableImageContainer = styled.div`
  position: relative;
  margin: 0 auto; 
  display: flex;
  justify-content: center;
  align-items: center;

  .react-transform-wrapper {
    width: 100%;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;

    .react-transform-component{
      height: auto;
      margin: 0;
    }
  }

  .arrow {
    position: relative;
    cursor: pointer;
    z-index: 100;
  }

  .arrow.down {
    bottom: -8rem;
    position: absolute;
  }

  .prev {
    left: -4rem;

    &.down {
      left: 4rem;
    }
  }

  .next {
    right: -4rem;

    &.down {
      right: 4rem;
    }
  }

  .tools {
    display: flex;
    flex-direction: column;
    position: absolute;
    bottom: 0;
    right: -2rem;
    z-index: 100;
    gap: 0.625rem;

    .zoom {
      width: 3rem;
      height: 3rem;
      radius: 0.625rem;
      background: rgba(0, 0, 0, 0.8);
      color: #FFF;
      border: 0;
      border-radius: 0.625rem;
    }
  }

  button {
    background: #fff;
    border: 1px solid #DBDCE0;
    padding: 0.3125rem 0.625rem;
    cursor: pointer;

    svg {
      width: 0.75rem;
      height: 0.75rem;
    }
  }

  img {
    height: auto; 
    margin: 0 auto;
    max-height: 70vh;
  }

  .modal-body {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    border-radius: 1.25rem;
  }

  .footer {
    display: flex;
    justify-content: space-between;
    background: black;
    width: 100%;
    border-bottom-right-radius: 1.25rem;
    border-bottom-left-radius: 1.25rem;
    height: 2.5rem;
    align-items: center;
    padding: 0 1.25rem;

    .object-info {
      flex: 1;
      text-align: center; 
      display: flex;
      gap: 0.625rem;
      justify-content: center;
      align-items: center;
    }
  }

  .download{
    cursor: pointer;
    display: flex;
    gap: 0.3125rem;
    align-items: flex-end;
    background: rgba(246, 246, 246, 1);
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
  }
 
`;




const AnalyticsWrapper = styled.div`
  display: flex;
  gap: 0.625rem;
  max-width: 100%;
  max-height: 100%;
  flex-wrap: wrap;
  
  .container{
    display: flex;
    flex-direction: column;
    background: var(--borderGray);
    border-radius: 0.625rem;
    height: 12.5rem;
    .title {
      width: 100%;
      border-radius: 0.625rem;
      border: 1px solid var(--borderGray);
      background: var(--white);
      font-size: 0.75rem;
      font-weight: 700;
      padding: 0.4375rem 0.625rem;
      min-height: 1.75rem;
      white-space: wrap;
      max-width: 20rem;
    }

    .chart-container {
      width: 20rem;
      background: var(--white);
      border-radius: 0.625rem;
      border: 1px solid var(--borderGray);
      padding: 0.625rem;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      position: relative;

      .doughnut-total {
        position: absolute;
        font-size: 1.5rem;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
      }

      .bar-total {
        position: absolute;
        font-size: 0.75rem;
        right: 0.5rem;
        top: 0;
      }
    }
  }

  .half {
    height: fit-content;
    .chart-container {
      height: 4.1875rem;
    }
  }

  .white {
    background: var(--white);
    gap: 0.625rem;
  }

  .labels { 
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 0.625rem;
    padding: 0.625rem;
    align-items: flex-start;
    justify-content: flex-start;  

    .label {
      display: flex;
      gap: 0.625rem;
      justify-content: flex-start;
      align-items: center;
    }
    
    .text {
      font-size: 0.625rem;  
      text-align: left;
    }

    .box{
      width: 0.5rem;
      height: 0.5rem;
    }
  }

  
`;

const AgricultureWrapper = styled.div`
  // padding: 0.625rem 0.625rem 0.625rem 1.25rem;
  .list-heading{
    margin-bottom: 0.625rem;
    display: flex;
    align-items: center;
    gap: 0.625rem;
    color: var(--primary-color);
    font-weight: 700;
    font-size: 1.2rem;
    line-height: 1.2rem;
    cursor: pointer;
    path {
      stroke: var(--primary-color);
    }
  }
`

const PassportWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.25rem;

  
  .item {
    padding: 0.625rem;
    border-radius: 0.625rem;
    background: var(--white);
    border: 1px solid var(--borderGray);
    display: flex;
    gap: 0.625rem;
  };

  .container {
    padding: 0.625rem;
    border-radius: 0.625rem;
    border: 1px solid var(--borderGray);
    background: var(--white);

    &.supervisor{
      background: var(--whiteSmoke);
    }

    &.police .item {
      border: 0;
    }
  }

  .photo {
    width: 6.25rem;
    height: 8.31369rem;
    border-radius: 0.625rem;
    border: 1px solid var(--borderGray);  
    cursor: pointer;
  };

  .info {
    width: 100%;
  }

  .name {
    color: var(--primary-color);
    font-family: Roboto;
    font-size: 1rem;
    font-weight: 700;
    text-transform: uppercase;
  }

  .group {
    gap: 0.625rem; 

    &.gray {
      display: flex;
      padding: 0.625rem;
      flex-direction: column;
      gap: 0.375rem;
      border-radius: 0.625rem;
      background: var(--whiteSmoke);
      max-width: 50%;
      margin-top: 0.625rem;
    }
  }

  .position {
    color: var(--shuttleGray);
    font-variant-numeric: lining-nums tabular-nums;
    font-size: 0.875rem;
    font-weight: 400;
  }

  .row {
    display: flex;
    gap: 0.625rem;
    align-items: center;
  }

  .works-from {
    padding: 0.125rem 0.3125rem;
    border-radius: 0.3125rem;
    background: var(--whiteSmoke);
    color: var(--shuttleGray);
    font-size: 0.875rem;
    font-weight: 300;
  }

  .date { 
    color: var(--shuttleGray);
    font-size: 0.875rem;
    font-weight: 300;
  }

  .divider {
    width: 100%;
    height: 1px;
    background: var(--borderGray);
    margin: 0.94rem 0;
  }

  .phone {
    display: flex;
    gap: 0.625rem;
    img {
      width: 0.875rem;
      height: 0.875rem;
    }
  }

  .number {
    font-size: 0.875rem;
    font-weight: 700;
    color: #2366CA;
    text-decoration: underline;
  }

  .other {
    color: var(--shuttleGray);
    font-size: 0.75rem;
    font-weight: 400;
  }

  .photo-container{
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    gap: 0.625rem;
  }

  .download-button {
    all: unset;
    cursor: pointer;
  }

 

`;

const SnpInfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.625rem;
  background: #fff;
  padding: 1.25rem;

  .container {
    display: flex;
    flex-direction: column;
    gap: 1.25rem;
    border-left: 3px solid var(--primary-color);
    border-radius: 0.3125rem;
    border-bottom: 1px solid #CFCFCF;
  }

  .header {
    height: 2.5rem;
    display: flex;
    justify-content: space-between;
    
    .left {
      display: flex;
      align-items: center;
      .icon {
        width: 2.5rem;
        height: 2.5rem;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }

  .snp-info {
    &__grid {
      display: flex;
      gap: 0.625rem;
      flex-wrap: wrap;
      padding: 0 0 1.25rem 1.25rem;;
    }
    
    &__item {
      background: #F6F6F6;
      height: 6.3125rem;
      width: 10.229375rem;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;  
      border-radius: 0.3125rem;
      text-align: center;
      gap: 0.625rem;
      &.total {
        border: 1px solid var(--borderGray);
        background: #FFF;
      }

      &.long {
        width: 20.666875rem;
        height: 6.5625rem;
      }
    }
  }

  
`;

const BasicStatsItem = styled.div<{ theme?: Theme }>`
  display: flex;
  flex-direction: column;
  border-radius: 0.3125rem;
  border-left: 2px solid var(--primary-color);

  .unit-container {
    display: flex;
    align-items: center;
    gap: 0.3125rem;
    font-size: 0.75rem;
    color: var(--text-color);
    // height: 1rem;
    svg {
      cursor: pointer;
      stroke: #818689;
    }
  }

  .title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: var(--bg-white-light);
    padding: 0.625rem 0.9375rem 0.625rem 0.625rem;
    border-radius: 0.3125rem 0.3125rem 0 0;
    border: 1px solid var(--primary-color);
    height: 2.875rem;

    .left {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      height: 100%;

      > div:nth-child(2) {
        line-height: 0.625rem; 
      }
    }

    .right {
      height: 100%;
      display: flex;
    }

    .with-icon {
      display: flex;
      gap: 0.625rem;
      align-items: center;
      svg {
        cursor: pointer;
      }
    }

    span {
      font-size: 0.75rem;
      color: var(--text-color);
    }

    .headers {
      > div {
        width: 3.75rem;
      }
    }
  }

  .body {
    background: var(--bg-color);
    display: flex;
    padding: 0 0.625rem;
    flex-direction: column;
    border-radius: 0 0 0.3125rem 0.3125rem ;
    flex: 1;
    border: 1px solid var(--primary-color);
    justify-content: center;

    .year-select {
      display: flex;
      gap: 0.625rem;
      justify-content: flex-end;
      padding: 0.25rem 0;

      .year-value {
        cursor: pointer;
      }
    }

    .chart-container {
      width: 100%;
    }

    .datalabels {
      display: flex;
      flex-direction: column;
      gap: 0.625rem;
      justify-content: space-evenly;
      height: 100%; 
      padding: 15px 0;
    }

    &__item {
      display: flex;
      gap: 0.625rem;
      padding: 0.625rem 0;
      border-bottom: 1px solid var(--borderGray); 
      align-items: center;
      flex: 1;

      .subType {
        flex: 1;
      }

      .currentYearVal {
        // min-width: 3.4375rem;
        white-space: nowrap;
        text-align: right;

        &.plan {
          text-align: center;
          border-radius: 0.3125rem;
          height: 1.3125rem;
          padding: 0 0.25rem;
          display: flex;
          align-items: center;
          justify-content: center;
        }

        &.fact {
          text-align: center;
          border-radius: 0.3125rem;
          height: 1.3125rem;
          padding: 0 0.25rem;
          background: var(--white)FFF;
          display: flex;
          align-items: center;
          justify-content: center;
        }

        &.plan,
        &.fact {
          display: flex;
          align-items: center;
        }

        &.centered {
          text-align: center;
          span {
            font-weight: 400;
            font-size: 0.75rem;
            color: #98A3A9;
          }
        }
      }

      &:last-child {
        border-bottom: 0;
        padding-bottom: 0.375rem;
      }

      .name {
        flex: 1;
      }

      .increase {
        display: flex;
        gap: 0.3125rem;
        align-items: baseline;

        div:first-child {
          display: flex;
          align-items: center;
          justify-content: end;
          gap: 0.3125rem;
        }

        svg {
          width: 1rem;
          height: 1rem;
        }
      }
      
      &.with-icon {
        align-items: center;
        gap: 1.25rem;
        justify-content: center;
        padding: 0.6125rem;
        width: 100%;
        margin: 0;

        .percent-value {
          max-width: 7.1875rem;
          min-width: 5.4375rem;
          text-align: center;
          white-space: nowrap;
          margin: 0 0.3125rem;
        }

        &:first-child {
          border-radius: 0.625rem 0.625rem 0 0;
        }

        &:last-child {
          border-radius: 0 0 0.625rem 0.625rem;
        }

        .icon {
          width: 1.25rem;
          height: 1.25rem;
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 50%;

          svg {
            width: 100%;
            width: 1.25rem;
            height: 1.25rem;
          }
        }

        &.ROAD {
          .increase {
            > div:last-child {
              display: flex;
              align-items: center;
              gap: 0.3125rem; 
            }
          }
        }
      }

      &.stretched {F
        width: 100%;
        justify-content: space-between;

        .percent-value {
          text-align: right;
          min-width: 3.75rem;
          margin: 0;
        }

        .increase {
          align-items: center;
        }

        img { 
          width: 1.25rem;
        }
      }
    }

    &.with-icon {
      padding: 0;
    }

    &.padded {
      padding: 0.625rem;
    }
  }

  .with-table {
    table {
     height: 100%;
      thead {
        td {
          text-align: center;
        }
      }
      tbody td {
        text-align: center;
        vertical-align: middle;
        padding: 0.3125rem 0;
        min-width: unset;
        border-bottom: 1px solid var(--borderGray);
      }

      tr:last-child {
        td {
          border-bottom: 0;
        }
      }

      tbody td:first-child {
        text-align: left;
      }
    }
  }

  .headers {
    display: flex;
    justify-content: flex-end;
    gap: 0.625rem;
    
    div {
      width: 3.4375rem;
      text-align: center;
    }

    .centered-header {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 4.8125rem;
    }
  }

  .monitoring-body {
    display: grid;
    grid-template-columns: 4fr 1fr 1fr 1fr;
    justify-content: center;
    align-items: center;
    justify-items: center;
    padding: 0.625rem 0.25rem 0.625rem 0.625rem;
    gap: 0.25rem;

    .text {
      display: flex;
      gap: 0.625rem;

      svg {
        min-width: 0.75rem;
      }

      a {
        font-size: 0.75rem;
        display: block;
        display: -webkit-box;
        max-width: 100%;
        height: 2.25rem;
        margin: 0 auto;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
        color: var(--mm-link);
      }
    }

    svg {
      width: 0.875rem;
      height: 0.875rem;
      path {
        fill: var(--primary-color);
      }

      &.share-icon {
        width: 1rem;
        height: 1rem;
        path {
          stroke: var(--primary-color);
          fill: none;
        }
      }
    }
    
  }

  .icon-container {
    display: flex;
    gap: 0.75rem;
    align-items: center;
  }

  .df {
    display: flex;
    gap: 0.625rem;
    align-items: flex-start;
    flex-direction: row;
    justify-content: space-between;
  }

  .grid-centered {
    justify-content: center;
    overflow: hidden;
    display: flex;
    // grid-template-columns: 1fr 1fr 1fr;
    flex-direction: column;
    width: 100%;
    align-items: center;
   
    &__column {
      display: flex;
      gap: 0.3125rem;
      align-items: flex-start;
      justify-content: center;
      padding: 0.3125rem 0;
      width: 100%;
      gap: 1.25rem;

      > div:first-child > div {
        white-space: break-word; 
        // max-width: 5rem;
        // text-transform: capitalize;
      }

      > div > div {
        text-align: center; 
      }

      > div {
        display: flex;
        flex-direction: column;
        gap: 0.3125rem;
        justify-content: center; 
        align-items: center;

        &:first-child {
          padding-top: 0.625rem;
          // align-self: center;
        }
      }

      &.total {
        background: #EEF1F8;
      }
    }

    &__upper,
    &__lower {
      display: flex;
      gap: 0.625rem;
      flex-direction: column;
      justify-content: center;

      > div {
        white-space: break-word;
        max-width: 5rem; 
      }
    }

    &__upper {
      border-bottom: 1px solid var(--borderGray);
      padding-bottom: 0.5rem;
    }

    &__column.total {
      .grid-centered__upper {
        border-bottom: 1px solid white;
      }
    }
  }

  .operators-list {
    display: flex;
    justify-content: center;
    gap: 0.5rem;
    align-items: center;
    padding: 1rem 0 0.625rem 0;

    img {
      width: 12%;
      max-height: 1.3rem;
    }
  }

  ${({ theme }) => theme === Theme.LIGHT && css`
    .icon {
      svg:not(.not-fill) path {
        fill: #118F84;
      }

      #road-icon rect {
        fill: #118F84;
      }
    }
   
  `}

  ${({ theme }) => theme === Theme.DARK && css`
   .icon {
    svg path {
      fill: #fff;
    }

    #road-icon rect {
      fill: #fff;
    }
   }
  `}


  .food-grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    border-top: 1px solid var(--borderGray);

    &__item {
      display: flex;
      border-bottom: 1px solid var(--borderGray);
      align-items: center;
      justify-content: space-between;
      padding: 0.625rem 0.3125rem;
      gap: 0.125rem;

      &:nth-child(odd) {
        border-right: 1px solid var(--borderGray);
      }

      &:nth-last-child(2),
      &:last-child {
        border-bottom: 0;
      }
    }
   
    svg {
      width: 1.25rem;
      height: 1.25rem;
    }
  }

  .district-income-grid {
    padding-top: 0.3125rem;
    display: grid;
    grid-template-columns: 2fr 1fr;

    > div {
      border-bottom: 1px solid var(--borderGray);
      height: 100%;

      &:not(.chart-container) {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        gap: 1rem;
        padding-top: 0.625rem;
      }

      &:nth-child(odd) {
        border-right: 1px solid var(--borderGray);
      }
    }
   
    .chart-container {
      min-height: 100%;
      max-width: 14rem;
    }
  }
  
  .budget-grid {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 0.75fr 1fr;

    &__header {
      text-align: center;
      padding-bottom: 0.3125rem;

      &:nth-child(5) {
        border-left: 3px solid var(--borderGray);
      }
    }

    &__item {
      padding: 0.625rem 0;
      display: flex;
      justify-content: center;
      align-items: center;
      border-bottom: 1px solid var(--borderGray);
      width: 100%;

      &:nth-child(11),
      &:nth-child(17),
      &:nth-child(23) {
        justify-content: flex-start;
        line-height: 0.75rem;
      }

      &:nth-child(15),
      &:nth-child(21),
      &:nth-child(27){
        border-left: 3px solid var(--borderGray);
      }
    }

    &__total {
      display: flex;
      align-items: center;
      gap: 0.625rem;
      padding-top: 0.3125rem;
      > div {
        height: 1.25rem;
        vertical-align: middle;
        display: flex;
        align-items: center;

        &:last-child {
          padding-bottom: 3px;
        }
      }
    }

    .span-3 {
      grid-column: 2 / span 3;
    }

    .span-2 {
      grid-column: span 1;
      border-left: 3px solid var(--borderGray);
    }

    .bordered {
      border-bottom: 1px solid var(--borderGray);
    }
  }


  &#tax {
    .tabs {
      display: flex;
      justify-content: space-between;
      text-transform: uppercase;
      padding: 0.3125rem 0;
      border-bottom: 1px solid var(--borderGray);
      > div {
        cursor: pointer;
        white-space: nowrap;
        &.selected {
          font-weight: 700;
        } 
      }
    }

    .tax-grid {
      display: grid;
      grid-template-columns: 2fr 1fr 1fr 1fr 1fr;
      grid-template-rows: 1fr 0.825fr 0.825fr 0.825fr 0.825fr;
      align-items: center; 
      // grid-auto-rows: 1fr;
      > div {
        // padding-top: 0.3125rem;
        // padding-bottom: 0.3125rem;
        align-items: center;
        display: flex;
        border-right: 1px solid var(--borderGray);
        // border-bottom: 1px solid var(--borderGray);
        height: 100%;
        line-height: 0.625rem;

        .wrap {
          width: 100%;

          &__item {
            width: 80%; 
            margin: 0 auto; 
            white-space: nowrap;
            text-align: right;
          }
        }

        // &:nth-child(2),
        // &:nth-child(6),
        // &:nth-child(8),
        &:nth-child(9),
        // &:nth-child(11),
        // &:nth-child(13),
        &:nth-child(14),
        // &:nth-child(16),
        // &:nth-child(18),
        &:nth-child(19),
        // &:nth-child(21),
        // &:nth-child(23),
        &:nth-child(24) {
          border-right: 0;
        }

        &:nth-child(5),
        &:nth-child(10),
        &:nth-child(15),
        &:nth-child(20) {
          display: flex;
          justify-content: flex-start;
          padding-left: 0.3125rem;
          padding-right: 0.3125rem;
          text-align: left;
        }
      }

      .header {
        border-bottom: 1px solid var(--borderGray);
        border-right: 1px solid var(--borderGray);
        display: flex;
        align-items: flex-start;
        min-height: 3rem;
        justify-content: center;
      }

      .total {
        justify-self: flex-start;
        padding-right: 3.75rem;
      }

      .merged {
        grid-column: span 2;
        flex-direction: column;
        display: flex;
        width: 100%;
        text-align: center;
        border-right: 0;
        align-items: center;
        justify-content: flex-start;
        padding-right: 0;

        > div {
          display: flex;
          justify-content: space-between;
        }

        .lower {
          width: 100%;
          padding: 0 0.25rem;
          div {
            flex: 1;
            line-height: 0.5rem;
          }
        }
      }
    }
  }

  .screening-item {
    &__table {
      padding: 0.3125rem 0;
      width: 100%;
      thead,
      tbody {
        width: 100%;
      }

      th {
        > div {
          display: flex;
          justify-content: flex-end;
          align-items: center;
          text-align: center;
        }
      }

      th:first-child {
        > div {
          justify-content: flex-start;
          text-align: left;
        }
      }

      td {
        text-align: right;
        .increase {
          display: flex;
          gap: 0.3125rem;
          align-items: center;
          justify-content: flex-end;
        }
      }

      td:first-child {
        text-align: left;
        > div {
          justify-content: flex-start; 
        }
      }
    }
  
  }
`;

const Filters = styled.div`
  height: 4.375rem;
  background: #fff;
  display: flex;
  align-items: center;
  padding: 0.625rem;
  justify-content: space-between;

  .close-icon {
    cursor: pointer;
    width: 3.125rem;
    height: 3.125rem;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #F6F6F6;
  }

  .header-text {
    display: flex;
    gap: 0.625rem;
    align-items: center;
    padding-left: 1.25rem;
  } 
`;

const LegendGrid = styled.div<{ columnNumber: number }>`
	display: flex;
	gap: 0.625rem;
  padding: 0.625rem 0 0.625rem 0.625rem;
  flex-wrap: wrap;
	& > div {
		display: flex;
		gap: 0.375rem;
		cursor: pointer;
    align-items: center;

		&.selected {
			text-decoration: line-through;
			${Text} {
				color: lightgray;
			}
		}
	}

	.circle {
		width: 10px;
		height: 10px;
		border-radius: 2px;
	}

	${({ columnNumber }) =>
    columnNumber &&
    css`
			grid-template-columns: repeat(${columnNumber}, 1fr);
		`};
`
const FullScreenTable = styled.table`
  width: 100%;
  thead {
    background: var(--white);
    border: 1px solid var(--borderGray);
    font-size: 0.75rem;
    width: 100%;

    tr {
      background: var(--bg-white);
    }

    td {
      padding: 0.75rem 1rem;
      text-align: center;
      vertical-align: middle;
      color: var(--text-color);
      .td-flex {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 0.3125rem;

        svg,
        path {
          cursor: pointer;
          min-width: 0.5625rem;
          min-height: 0.79125rem;
        }
      }
    }
  }

  tbody {
    td { 
      padding: 0.75rem 2rem;
      text-align: center;
      font-weight: 500;
      color: var(--text-color);
    }

    tr:nth-child(even) {
      background: var(--bg-white);
    }

    tr:nth-child(odd) {
      background: var(--budget-odd-row);
    }
  }

  .name {
    text-align: left;
  }

  @media (max-width: 1440px) {
    div,
    td {
      font-size: 0.625rem;
    }
  }
`;

export {
  Heading,
  Info,
  NavBar,
  NavItem,
  FloatingTabsContainer,
  TabButton,
  HeaderContainer,
  Item,
  RightMenuContainer,
  EducationContainer,
  EducationItem,
  ZoomableImageContainer,
  AnalyticsWrapper,
  AgricultureWrapper,
  PassportWrapper,
  SnpInfoWrapper,
  BasicStatsItem,
  Filters,
  LegendGrid,
  FullScreenTable
}
