import React, { ChangeEvent, Fragment, useCallback, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { Footer, FooterButton, FormBlock, FormGroup, Wrapper } from '../../components/admin-page/admin-page.styles';
import { Form, Formik, Field } from 'formik';
import { GoUp, Save } from '../../assets/icons';
import { checkVal, findIndexByKey, getIncreaseVal, getLang, getYearVal, handleGoUp, isRegion, OBLAST_KATO, t, updateKatoAccessList, years } from '../../utils/helpers.utils';
import { toast, ToastContainer } from 'react-toastify';
import { getGeneralInfo } from '../../requests/snp.request';
import { updateFormPartial } from '../../requests/supervisor.request';

const SERIndicatorsPage = () => {
  const { i18n: { language } } = useTranslation();
  const navigate = useNavigate();
  const { kato } = useParams();

  const formikRef = useRef<any>(null);
  const wrapperRef = useRef<any>(null);

  const [regions, setRegions] = useState<any[]>([]);
  const [snps, setSnps] = useState<any[]>([]);
  const [region, setRegion] = useState<number>(0);
  const [snp, setSnp] = useState<number>(0);
  const [katoAccessList, setKatoAccessList] = useState<number[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isKatoRegion, setIsKatoRegion] = useState(false);
  const [openIds, setOpenIds] = useState<{ [id: string]: boolean }>({});
  const [groupedStats, setGroupedStats] = useState<any>({});


  const [basicStats, setBasicStats] = useState<any>(null);
  const [errors, setErrors] = useState<any>({});

  const validateForm = (data: any) => {
    setErrors({});
    let res = true;

    //SECTION FIVE
    const SERData = basicStats.filter((item: any) => item.type === 'SER' && item.subType !== 'HOUSING')
    if (kato && isRegion(+kato) && SERData.some((item: any) => checkVal(item.currentYearVal) || checkVal(item.previousYearVal) || checkVal(item.ifo))) {
      for (const item of SERData) {
        if (!checkVal(item.currentYearVal) || !checkVal(item.previousYearVal) || !checkVal(item.ifo)) {
          setErrors((prev: any) => ({ ...prev, [`SER-${item.subType}`]: true }))
          toast.error(t(`errors.streetName`, language));
          return false;
        }
      }
    }

    // SECTION FIVE.ONE 
    const SERBudgetData = basicStats.filter((item: any) => item.type === 'BUDGET')
    if (SERBudgetData.some((item: any) => checkVal(item.currentYearVal) || checkVal(item.previousYearVal))) {
      for (const item of SERBudgetData) {
        if (!checkVal(item.currentYearVal) || !checkVal(item.previousYearVal)) {
          setErrors((prev: any) => ({ ...prev, [`BUDGET-${item.subType}`]: true }))
          toast.error(t(`errors.streetName`, language));
          return false;
        }
      }
    }

    // SECTION FIVE.TWO
    const SERMCBData = basicStats.filter((item: any) => item.type === 'MCB')
    if (SERMCBData.some((item: any) => checkVal(item.currentYearVal) || checkVal(item.previousYearVal) || checkVal(item.ifo))) {
      for (const item of SERMCBData) {
        if (!checkVal(item.currentYearVal) || !checkVal(item.previousYearVal) || !checkVal(item.ifo)) {
          setErrors((prev: any) => ({ ...prev, [`MCB-${item.subType}`]: true }))
          toast.error(t(`errors.streetName`, language));
          return false;
        }
      }
    }

    // SECTION FIVE.THREE
    const SEREmploymentData = basicStats.filter((item: any) => item.type === 'EMPLOYMENT')
    if (SEREmploymentData.some((item: any) => checkVal(item.currentYearVal) || checkVal(item.previousYearVal) || checkVal(item.ifo))) {
      for (const item of SEREmploymentData) {
        if (!checkVal(item.currentYearVal) || !checkVal(item.previousYearVal) || !checkVal(item.ifo)) {
          setErrors((prev: any) => ({ ...prev, [`EMPLOYMENT-${item.subType}`]: true }))
          toast.error(t(`errors.streetName`, language));
          return false;
        }
      }
    }

    // SECTION FIVE.FOUR
    const SERLivestockData = basicStats.filter((item: any) => item.type === 'LIVESTOCK')
    if (SERLivestockData.some((item: any) => checkVal(item.currentYearVal) || checkVal(item.previousYearVal) || checkVal(item.ifo))) {
      for (const item of SERLivestockData) {
        if (!checkVal(item.currentYearVal) || !checkVal(item.previousYearVal) || !checkVal(item.ifo)) {
          setErrors((prev: any) => ({ ...prev, [`LIVESTOCK-${item.subType}`]: true }))
          toast.error(t(`errors.streetName`, language));
          return false;
        }
      }
    }

    return res;
  }

  const handleSubmitForm = (values: any) => {
    setErrors({});

    const data = {
      basicStats,
    }

    if (kato && validateForm(data)) {
      updateFormPartial(kato, data)
        .then(() => {
          toast.success(t('toast.save_success'))
          loadForm();
        })
        .catch(() => toast.error('Ошибка при сохранении'));
    }
  }

  const handleRegionChange = (e: ChangeEvent<HTMLSelectElement>) => {
    setRegion(+e.target.value);
    setSnps(regions.find((item) => +item.value === +e.target.value)?.children || []);

    kato && isRegion(+kato) && navigate(`/admin/${e.target.value}/ser`)
  }

  const handleSnpChange = (e: ChangeEvent<HTMLSelectElement>) => {
    setSnp(+e.target.value)
    navigate(`/admin/${e.target.value}`)
  }

  const loadForm = useCallback(() => {
    const val = kato ? kato : snp
    val && getGeneralInfo(+val).then((res: any) => {
      const grouped = res.basicStats.filter((item: any) => ['SER', 'BUDGET', 'MCB', 'LIVESTOCK', 'EMPLOYMENT'].includes(item.type)).reduce((acc: any, stat: any) => {
        if (!acc[stat.type]) {
          acc[stat.type] = [];
        }
        acc[stat.type].push(stat);
        return acc;
      }, {});

      kato && !isRegion(+kato) && delete grouped['SER'];

      setGroupedStats(grouped)
      setBasicStats(res.basicStats);
      setIsLoading(false);

      const temp = res.productSelfSufficiencyStats.reduce((acc: any, item: any) => {
        acc[item.productNameRu] = openIds[item.productNameRu] || false;
        return acc;
      }, {})

      setOpenIds(temp);
    });


  }, [region, snp, kato])

  const renderSelects = (lang: 'Ru' | 'Kz' = 'Ru') => {
    return (
      kato && +kato !== OBLAST_KATO && <div className="grid-item">
        <FormGroup>
          <label htmlFor="region">{t(`form.region.name`, lang)}</label>
          <Field as="select" value={region} onChange={handleRegionChange} disabled={lang.toLowerCase() !== language}>
            {regions.map((item) => <option key={item.value} value={item.value}>{lang === 'Kz' ? item.labelKz : item.label}</option>)}
          </Field>
        </FormGroup>

        {!isKatoRegion && <FormGroup>
          <label htmlFor="snp">{t(`form.snp.name`, lang)}</label>
          <Field as="select" value={snp} onChange={handleSnpChange} disabled={lang.toLowerCase() !== language}>
            {snps.map((item) => <option key={item.value} value={item.value}>{lang === 'Kz' ? item.labelKz : item.label}</option>)}
          </Field>
        </FormGroup>}
      </div>
    )
  }

  const renderFields = (lang: 'Ru' | 'Kz', setFieldValue: any) => {
    return <div className="grid-item">
      {basicStats && Object.keys(groupedStats).map((type: any) => (
        <FormBlock type='white'>
          <div className="title bold">{t(`new-fields.${type}`, lang)}</div>
          {
            groupedStats[type].filter((stat: any) => stat.subType !== 'HOUSING').map((stat: any) => (
              <FormBlock type="white" key={stat.subType}>
                <div className="title bold">
                  {t(`new-fields.${stat.subType}`, lang)}
                </div>

                {
                  years.map((year) => (
                    <FormGroup key={year}>
                      <label
                        htmlFor={`basicStats[${findIndexByKey(`${stat.type}-${stat.subType}`, basicStats)}].${getYearVal(year)}`}
                        className="required"
                      >
                        {t(`new-fields.${stat.type}-${stat.subType}`, lang)
                          .replace('{{ type }}', `${year === 2023 ? 'план' : 'факт'}`)
                          .replace('млрд', type === 'SER' && kato && +kato !== OBLAST_KATO ? 'млн' : 'млрд')}
                        {' '}{type === 'BUDGET' ? '2024' : year}
                      </label>

                      <Field
                        name={`basicStats[${findIndexByKey(`${stat.type}-${stat.subType}`, basicStats)}].${getYearVal(year)}`}
                        type="number"
                        as="input"
                        value={basicStats[findIndexByKey(`${stat.type}-${stat.subType}`, basicStats)]?.[getYearVal(year)] || ''}
                        onChange={(e: any) => {
                          const val = e.target.value;
                          if (val === '') {
                            setFieldValue(`basicStats[${findIndexByKey(`${stat.type}-${stat.subType}`, basicStats)}].${getYearVal(year)}`, '');
                            setBasicStats(
                              basicStats.map((item: any, i: number) => i === findIndexByKey(`${stat.type}-${stat.subType}`, basicStats) ? {
                                ...item,
                                [getYearVal(year)]: ''
                              } : item)
                            )
                          } else {
                            setFieldValue(`basicStats[${findIndexByKey(`${stat.type}-${stat.subType}`, basicStats)}].${getYearVal(year)}`, Math.max(0, val).toString());
                            setBasicStats(
                              basicStats.map((item: any, i: number) => i === findIndexByKey(`${stat.type}-${stat.subType}`, basicStats) ? {
                                ...item,
                                [getYearVal(year)]: Math.max(0, val).toString()
                              } : item)
                            )
                          }
                        }}
                        className={`${errors[`${stat.type}-${stat.subType}`]
                          ? 'error'
                          : ''
                          }`}
                      />
                    </FormGroup>
                  ))
                }
                <FormGroup>
                  <label
                    htmlFor={`${stat.type}-${stat.subType}-increaseVal`}
                    className="required"
                  >
                    {t(`new-fields.${stat.type}-${stat.subType}-increaseVal`, lang)}
                  </label>

                  <Field
                    name={`basicStats[${findIndexByKey(`${stat.type}-${stat.subType}`, basicStats)}]-increaseVal`}
                    type="number"
                    as="input"
                    value={getIncreaseVal(stat, basicStats) !== null ? getIncreaseVal(stat, basicStats) : basicStats[findIndexByKey(`${stat.type}-${stat.subType}`, basicStats)]?.increaseVal}
                    disabled
                  />
                </FormGroup>
                {type !== 'BUDGET' && <FormGroup>
                  <label
                    htmlFor={`basicStats[${findIndexByKey(`${stat.type}-${stat.subType}`, basicStats)}].ifo`}
                    className="required"
                  >
                    {t('new-fields.ifo', lang)}
                  </label>
                  <Field
                    name={`basicStats[${findIndexByKey(`${stat.type}-${stat.subType}`, basicStats)}].ifo`}
                    type="number"
                    as="input"
                    value={basicStats[findIndexByKey(`${stat.type}-${stat.subType}`, basicStats)]?.ifo || ''}
                    onChange={(e: any) => {
                      const val = e.target.value;
                      if (val === '') {
                        setFieldValue(`basicStats[${findIndexByKey(`${stat.type}-${stat.subType}`, basicStats)}].ifo`, '');
                        setBasicStats(
                          basicStats.map((item: any, i: number) => i === findIndexByKey(`${stat.type}-${stat.subType}`, basicStats) ? {
                            ...item,
                            ifo: ''
                          } : item)
                        )
                      } else {
                        setFieldValue(`basicStats[${findIndexByKey(`${stat.type}-${stat.subType}`, basicStats)}].ifo`, Math.max(0, val).toString());
                        setBasicStats(
                          basicStats.map((item: any, i: number) => i === findIndexByKey(`${stat.type}-${stat.subType}`, basicStats) ? {
                            ...item,
                            ifo: Math.max(0, val).toString()
                          } : item)
                        )
                      }
                    }}
                    className={`${errors[`${stat.type}-${stat.subType}`]
                      ? 'error'
                      : ''
                      }`}
                  />
                </FormGroup>}

                {type === 'BUDGET' && <FormGroup>
                  <label
                    htmlFor={`basicStats[${findIndexByKey(`${stat.type}-${stat.subType}`, basicStats)}].additionalInfo.development`}
                    className="required"
                  >
                    {t('new-fields.development', lang)}
                  </label>
                  <Field
                    name={`basicStats[${findIndexByKey(`${stat.type}-${stat.subType}`, basicStats)}].additionalInfo.development`}
                    type="number"
                    as="input"
                    value={basicStats[findIndexByKey(`${stat.type}-${stat.subType}`, basicStats)]?.additionalInfo?.development || ''}
                    onChange={(e: any) => {
                      const val = e.target.value;
                      if (val === '') {
                        setFieldValue(`basicStats[${findIndexByKey(`${stat.type}-${stat.subType}`, basicStats)}].additionalInfo.development`, '');
                        setBasicStats(
                          basicStats.map((item: any, i: number) => i === findIndexByKey(`${stat.type}-${stat.subType}`, basicStats) ? {
                            ...item,
                            additionalInfo: {
                              ...item.additionalInfo,
                              development: ''
                            }
                          } : item)
                        )
                      } else {
                        setFieldValue(`basicStats[${findIndexByKey(`${stat.type}-${stat.subType}`, basicStats)}].development`, Math.max(0, val).toString());
                        setBasicStats(
                          basicStats.map((item: any, i: number) => i === findIndexByKey(`${stat.type}-${stat.subType}`, basicStats) ? {
                            ...item,
                            additionalInfo: {
                              ...item.additionalInfo,
                              development: Math.max(0, val).toString()
                            }
                          } : item)
                        )
                      }
                    }}
                    className={`${errors[`${stat.type}-${stat.subType}`]
                      ? 'error'
                      : ''
                      }`}
                  />
                </FormGroup>}

                {
                  kato && +kato === OBLAST_KATO && type === 'SER' && <FormGroup>
                    <label
                      htmlFor={`basicStats[${findIndexByKey(`${stat.type}-${stat.subType}`, basicStats)}].ifoPlace`}
                      className="required"
                    >
                      {t('new-fields.ifoPlace', lang).replace('{{ type }}', `${kato && +kato === OBLAST_KATO ? 'регионов' : 'районов'}`)}
                    </label>
                    <Field
                      name={`basicStats[${findIndexByKey(`${stat.type}-${stat.subType}`, basicStats)}].ifoPlace`}
                      type="number"
                      as="input"
                      value={basicStats[findIndexByKey(`${stat.type}-${stat.subType}`, basicStats)]?.ifoPlace || ''}
                      onChange={(e: any) => {
                        const val = e.target.value;
                        if (val === '') {
                          setFieldValue(`basicStats[${findIndexByKey(`${stat.type}-${stat.subType}`, basicStats)}].ifoPlace`, '');
                          setBasicStats(
                            basicStats.map((item: any, i: number) => i === findIndexByKey(`${stat.type}-${stat.subType}`, basicStats) ? {
                              ...item,
                              ifoPlace: Math.max(0, val).toString()
                            } : item)
                          )
                        } else {
                          setFieldValue(`basicStats[${findIndexByKey(`${stat.type}-${stat.subType}`, basicStats)}].ifoPlace`, Math.max(0, val).toString());
                          setBasicStats(
                            basicStats.map((item: any, i: number) => i === findIndexByKey(`${stat.type}-${stat.subType}`, basicStats) ? {
                              ...item,
                              ifoPlace: Math.max(0, val).toString()
                            } : item)
                          )
                        }
                      }}
                      className={`${errors[`${stat.type}-${stat.subType}`]
                        ? 'error'
                        : ''
                        }`}
                    />
                  </FormGroup>
                }

                {/* {
                  (type === 'SER' || (kato && +kato !== OBLAST_KATO && isRegion(+kato))) && <FormGroup>
                    <label
                      htmlFor={`basicStats[${findIndexByKey(`${stat.type}-${stat.subType}`, basicStats)}].additionalInfo.place`}
                      className="required"
                    >
                      {kato && +kato === OBLAST_KATO ? t('new-fields.oblast-place', lang) : t('new-fields.region-place', lang)}
                    </label>
                    <Field
                      name={`basicStats[${findIndexByKey(`${stat.type}-${stat.subType}`, basicStats)}].additionalInfo.place`}
                      type="number"
                      as="input"
                      value={basicStats[findIndexByKey(`${stat.type}-${stat.subType}`, basicStats)]?.additionalInfo?.place || ''}
                      onChange={(e: any) => {
                        const val = e.target.value;
                        if (val === '') {
                          setFieldValue(`basicStats[${findIndexByKey(`${stat.type}-${stat.subType}`, basicStats)}].additionalInfo.place`, '');
                          setBasicStats(
                            basicStats.map((item: any, i: number) => i === findIndexByKey(`${stat.type}-${stat.subType}`, basicStats) ? {
                              ...item,
                              additionalInfo: {
                                ...item.additionalInfo,
                                place: ''
                              }
                            } : item)
                          )
                        } else {
                          setFieldValue(`basicStats[${findIndexByKey(`${stat.type}-${stat.subType}`, basicStats)}].place`, Math.max(0, val).toString());
                          setBasicStats(
                            basicStats.map((item: any, i: number) => i === findIndexByKey(`${stat.type}-${stat.subType}`, basicStats) ? {
                              ...item,
                              additionalInfo: {
                                ...item.additionalInfo,
                                place: Math.max(0, val).toString()
                              }
                            } : item)
                          )
                        }
                      }}
                      className={`${errors[`${stat.type}-${stat.subType}`]
                        ? 'error'
                        : ''
                        }`}
                    />
                  </FormGroup>
                } */}
              </FormBlock>
            ))
          }
        </FormBlock>
      ))}
    </div>
  }

  useEffect(() => {
    loadForm();
  }, [loadForm, snp]);

  useEffect(() => {
    kato && updateKatoAccessList(katoAccessList, kato, navigate, setRegions, setRegion, setSnps, setSnp);
  }, [katoAccessList, kato]);

  useEffect(() => {
    if (kato) {
      setIsKatoRegion(isRegion(+kato))
    }
  }, [kato])

  useEffect(() => {
    const item = localStorage.getItem('user');
    if (item) {
      const snpInfo = JSON.parse(item);
      if (snpInfo && snpInfo.kato_access) {
        setKatoAccessList(snpInfo.kato_access)
      }
    }
  }, [])

  return (
    <div style={{ position: 'relative' }}>
      {
        !isLoading && <>
          <Wrapper ref={wrapperRef}>
            <Formik
              initialValues={{}}
              onSubmit={(values) => {
                handleSubmitForm(values);;
              }}
              innerRef={formikRef}
            >
              {({ setFieldValue }) => (
                <Form>
                  {renderSelects(getLang())}
                  {renderSelects(getLang() !== 'Kz' ? 'Kz' : 'Ru')}
                  {renderFields(getLang(), setFieldValue)}
                  {renderFields(getLang() !== 'Kz' ? 'Kz' : 'Ru', setFieldValue)}
                  <Footer>
                    <div className="buttons">
                      <FooterButton variant="save"><Save /> {t('save', language)}</FooterButton>
                    </div>
                    <div className="buttons">
                      <FooterButton variant="go-up" type='button' onClick={() => handleGoUp(wrapperRef)}><GoUp /> {t('go-up', language)}</FooterButton>
                    </div>
                  </Footer>
                </Form>
              )}
            </Formik>
          </Wrapper>
        </>
      }
      <ToastContainer />
    </div>
  )
}

export default SERIndicatorsPage