import React, { FC, useEffect, useState } from 'react'
import { SERContainer, SERTable } from './index.styles'
import { Text } from '../../text.component'
import { DeleteX, SortIcon2, TrendDown, TrendUp } from '../../../assets/icons'
import { SER1, SER10, SER11, SER12, SER13, SER14, SER16, SER17, SER18, SER19, SER2, SER20, SER21, SER22, SER23, SER24, SER25, SER26, SER27, SER28, SER29, SER3, SER30, SER31, SER4, SER5, SER6, SER7 } from '../../../assets/icons/ser'
import { isRegion, OBLAST_KATO, t } from '../../../utils/helpers.utils'
import { BasicStatsItem } from '../index.styles'
import Operstab from '../tabs/operstab.component'

const SERIcons: any = {
  'INDUSTRIAL_PRODUCTION': <SER1 />,
  'MINING_INDUSTRY': <SER2 />,
  'MANUFACTURING_INDUSTRY': <SER3 />,
  'AGRICULTURE': <SER4 />,
  'INVESTMENT': <SER5 />,
  'HOUSE_COMMISSIONING': <SER6 />,
  'CONSTRUCTION': <SER7 />,
  'TRANSPORT_WAREHOUSING': <SER29 />,
  'CONNECTION': <SER30 />,
  'INCOME_RECEIPT': <SER10 />,
  'BUDGET_EXPENDITURE': <SER10 />,
  'CURRENT_MSP': <SER11 />,
  'RETAIL': <SER12 />,
  'WHOLESALE': <SER13 />,
  'EMPLOYED': <SER16 />,
  'UNEMPLOYED': <SER17 />,
  'NEW_WORKPLACE': <SER18 />,
  'AVERAGE_MONTHLY_SALARY': <SER19 />,
  'KRC': <SER20 />,
  'SHEEP': <SER21 />,
  'GOAT': <SER22 />,
  'HORSE': <SER23 />,
  'CAMEL': <SER24 />,
  'BIRD': <SER25 />,
  'MEAT': <SER26 />,
  'COW_MILK': <SER27 />,
  'CHICKEN_EGG': <SER28 />,
  'TRADE': <SER31 />
}

const keyToKey: any = {
  'Инвестиции в основной капитал': 'INVESTMENT',
  'Объем строительных работ': 'CONSTRUCTION',
  'Промышленность': 'INDUSTRIAL_PRODUCTION',
  'Сельское хозяйство': 'AGRICULTURE',
  'Горнодобывающая промышленность': 'MINING_INDUSTRY',
  'Обрабатывающая промышленность': 'MANUFACTURING_INDUSTRY',
  'Ввод жилья': 'HOUSE_COMMISSIONING',
  'Транспорт и складирование': 'TRANSPORT_WAREHOUSING',
  'Связь': 'CONNECTION',
  'Торговля': 'TRADE',
}

const order = [
  'INVESTMENT',
  'INDUSTRIAL_PRODUCTION',
  'MANUFACTURING_INDUSTRY',
  'CONSTRUCTION',
  'AGRICULTURE',
  'HOUSE_COMMISSIONING',
  'TRADE'
]

const keyToKeyInverted = Object.keys(keyToKey).reduce((acc: any, key: any) => {
  acc[keyToKey[key]] = key
  return acc
}, {})

const tabs = ['SER', 'OPERSTAB', 'BUDGET', 'MCB', 'EMPLOYMENT', 'LIVESTOCK'];

interface IDataItem {
  subType: string;
  currentYearVal?: number;
  increaseVal?: number;
  additionalInfo?: {
    place?: string;
    development?: string;
    IFOPlace?: string;
  };
  ifo?: number;
  ifoPlace?: string;
}

interface IData {
  SER?: IDataItem[];
  BUDGET?: IDataItem[];
  MCB?: IDataItem[];
  EMPLOYMENT?: IDataItem[];
  LIVESTOCK?: IDataItem[];
}

interface IProps {
  DATA: IData;
  kato: string | number; // Consider specifying a more accurate type if possible
}


const SER: FC<IProps> = ({ DATA, kato }) => {

  const [data, setData] = useState<any>(null);
  const [selectedTab, setSelectedTab] = useState(tabs[0]);

  const [sortKey, setSortKey] = useState<string | null>(null);
  const [sortDirection, setSortDirection] = useState<'asc' | 'desc'>('asc');

  const [fullScreen, setFullScreen] = useState<any>({
    isOpen: false,
    type: null
  });

  const config = {
    SER: [
      [
        {
          key: 'name',
          label: 'Показатель',
          visible: true,
          colSpan: 2,
          rowSpan: 2,
        },
        {
          key: 'ifo',
          label: 'ИФО %',
          visible: true,
          rowSpan: 2,
        },
        {
          key: 'increase',
          label: 'Изменение за год',
          visible: true,
          rowSpan: 2,
        },
        {
          key: 'value',
          label: 'Значение',
          visible: true,
          rowSpan: 2,
        },
        {
          key: 'regionPlace',
          label: `Место среди ${+kato === OBLAST_KATO ? 'регионов' : 'районов'}`,
          visible: true,
          colSpan: 2,
          rowSpan: 1,
        }
      ],
      [
        {
          key: 'ifoPlace',
          label: 'ИФО, %',
          visible: true,
        },
        {
          key: 'place',
          label: 'Объем, млр., тенге',
          visible: true,
        }
      ]
    ],
    BUDGET: [
      [
        {
          key: 'name',
          label: 'Показатель',
          visible: true,
          colSpan: 2,
          rowSpan: 2,
        },
        {
          key: 'value',
          label: 'Значение',
          visible: true,
          rowSpan: 2,
        },
        {
          key: 'development',
          label: 'Исполнение за плановый период',
          visible: true,
          rowSpan: 2,
        },
        {
          key: 'increase',
          label: 'Исполнение за год',
          visible: true,
          rowSpan: 2,
        },
        {
          key: 'regionPlace',
          label: `Место среди районов`,
          visible: +kato !== OBLAST_KATO && isRegion(+kato),
          colSpan: 2,
          rowSpan: 1,
        }
      ],
      [
        {
          key: 'ifoPlace',
          label: 'ИФО, %',
          visible: +kato !== OBLAST_KATO && isRegion(+kato),
        },
        {
          key: 'place',
          label: 'Объем, млр., тенге',
          visible: +kato !== OBLAST_KATO && isRegion(+kato),
        }
      ]
    ],
    OTHER: [
      [
        {
          key: 'name',
          label: 'Показатель',
          visible: true,
          colSpan: 2,
          rowSpan: 2,
        },
        {
          key: 'ifo',
          label: 'ИФО %',
          visible: true,
          rowSpan: 2,
        },
        {
          key: 'increase',
          label: 'Изменение за год',
          visible: true,
          rowSpan: 2,
        },
        {
          key: 'value',
          label: 'Значение',
          visible: true,
          rowSpan: 2,
        },
        {
          key: 'regionPlace',
          label: `Место среди районов`,
          visible: +kato !== OBLAST_KATO && isRegion(+kato),
          colSpan: 2,
          rowSpan: 1,
        }
      ],
      [
        {
          key: 'ifoPlace',
          label: 'ИФО, %',
          visible: +kato !== OBLAST_KATO && isRegion(+kato),
        },
        {
          key: 'place',
          label: 'Объем, млр., тенге',
          visible: +kato !== OBLAST_KATO && isRegion(+kato),
        }
      ]
    ]
  }

  const links: any = {
    INDUSTRIAL_PRODUCTION: "https://app.powerbi.com/view?r=eyJrIjoiYmM2MDcxNGQtNTFjYi00ZWIwLThkZTktYzNhNmYwYWMyNzk1IiwidCI6ImU2MjQzNTFhLWViZGItNDUwMi1hNGEzLTRiZWI3OTYxODBlZiIsImMiOjl9",
    MANUFACTURING_INDUSTRY: "https://app.powerbi.com/view?r=eyJrIjoiOWFmYjBiMTAtZGZlMy00ZTFkLTgzNTQtZDQyYWMyZTUwNTRhIiwidCI6ImU2MjQzNTFhLWViZGItNDUwMi1hNGEzLTRiZWI3OTYxODBlZiIsImMiOjl9",
    CONSTRUCTION: "https://app.powerbi.com/view?r=eyJrIjoiYTNmMGQ0ZDQtYjg2Yy00M2EzLTgwYzktZWU3Njc1MTc1YzU2IiwidCI6ImU2MjQzNTFhLWViZGItNDUwMi1hNGEzLTRiZWI3OTYxODBlZiIsImMiOjl9",
    INVESTMENT: "https://app.powerbi.com/view?r=eyJrIjoiOWJkOTU2Y2MtNGQ2Ny00ZTM1LWJmOTUtMGMwYTM2YmIyMjlhIiwidCI6ImU2MjQzNTFhLWViZGItNDUwMi1hNGEzLTRiZWI3OTYxODBlZiIsImMiOjl9",
    HOUSE_COMMISSIONING: "https://app.powerbi.com/view?r=eyJrIjoiMWViYTk2ZjAtZGY2Yi00N2YzLTllNjktZTI1ZjQ0MjRiMGNkIiwidCI6ImU2MjQzNTFhLWViZGItNDUwMi1hNGEzLTRiZWI3OTYxODBlZiIsImMiOjl9",
    TRADE: "https://app.powerbi.com/view?r=eyJrIjoiNGMxMTk1ODEtNTkwYS00NDI0LWJjZTQtOTIzYTI0NTAwOGNlIiwidCI6ImU2MjQzNTFhLWViZGItNDUwMi1hNGEzLTRiZWI3OTYxODBlZiIsImMiOjl9",
    AGRICULTURE: "https://app.powerbi.com/view?r=eyJrIjoiMTNlNDZlMTYtYjllOS00YjE5LTlmODQtMmMzNmI3YmQ4Y2E3IiwidCI6ImU2MjQzNTFhLWViZGItNDUwMi1hNGEzLTRiZWI3OTYxODBlZiIsImMiOjl9",
  }

  const handleSort = (key: string) => {
    if (sortKey === key) {
      setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
    } else {
      setSortKey(key);
      setSortDirection('asc');
    }

    setData((prevData: any) => ({
      ...prevData,
      [selectedTab]: prevData[selectedTab]?.slice().sort((a: IDataItem, b: IDataItem) => {
        const aValue = a[key as keyof IDataItem] ?? '';
        const bValue = b[key as keyof IDataItem] ?? '';

        if (key === 'name' && typeof aValue === 'string' && typeof bValue === 'string') {
          if (sortDirection === 'asc') {
            return aValue.localeCompare(bValue);
          } else {
            return bValue.localeCompare(aValue);
          }
        } else {
          const aNum = +(aValue.toString().replaceAll(',', '.')) || 0;
          const bNum = +(bValue.toString().replaceAll(',', '.')) || 0;


          if (sortDirection === 'asc') {
            return aNum - bNum;
          } else {
            return bNum - aNum;
          }
        }
      }),
    }));
  };

  const getTHead = () => {
    if (['SER', 'BUDGET', 'MCB', 'EMPLOYMENT', 'LIVESTOCK'].includes(selectedTab)) {
      return (
        <thead>
          <tr>
            {(['SER', 'BUDGET'].includes(selectedTab) ? config[selectedTab as keyof typeof config] : config['OTHER'])[0].map((item: any, index: number) => (
              item.visible && <th
                key={index}
                onClick={() => handleSort(item.key)}
                colSpan={item.colSpan || 1}
                rowSpan={item.rowSpan || 1}
                className={item.key === 'regionPlace' ? 'no-border' : ''}
              >
                <div>
                  <Text fontSize='0.75rem' color='#7C7E7C' fontWeight={400}>{item.label}</Text>
                  {item.key !== 'regionPlace' && <SortIcon2 />}
                </div>
              </th>
            ))}
          </tr>
          <tr className='no-border'>
            {(['SER', 'BUDGET'].includes(selectedTab) ? config[selectedTab as keyof typeof config] : config['OTHER'])[1].map((item: any, index: number) => (
              item.visible && <th key={index}>
                <Text fontSize='0.75rem' color='#000105' fontWeight={400}>{item.label}</Text>
              </th>
            ))}
          </tr>
        </thead>
      );
    }
    return <thead></thead>;
  }

  const renderTableBody = (items: any[], additionalFields: boolean = false, type: string) => {
    return (
      <tbody>
        {items.map((item: any, index: number) => (
          <tr key={index}>
            <td>{item?.icon}</td>
            <td
              onClick={() => { item.link && setFullScreen({ isOpen: true, type: item.subType }) }}
              style={{ cursor: item.link ? 'pointer' : 'default' }}
            >{item?.name}
            </td>
            <td>{item?.ifo || '-'}</td>
            <td><Text
              fontSize='0.75rem'
              color={item?.increase > 0 ? "var(--success-green)" : "var(--failure-red)"}
              fontWeight={500}
              style={{ minWidth: '11rem' }}>
              {item?.increase > 0 ? <TrendUp width={10} height={8} /> : <TrendDown width={10} height={8} />}
              {' '}{item?.increase} {item?.add || '%'}
            </Text></td>
            <td>{item?.value || '-'}  {item?.add}</td>
            {additionalFields && (
              <>
                <td>{item?.ifoPlace || '-'} {+kato !== OBLAST_KATO ? '/ 13' : '/ 20'}</td>
                <td>{item?.place || '-'} {+kato !== OBLAST_KATO ? '/ 13' : '/ 20'}</td>
              </>
            )}
          </tr>
        ))}
      </tbody>
    );
  };

  const getTBody = () => {
    if (selectedTab === 'SER') {
      return renderTableBody(data.SER, true, selectedTab);
    } else if (selectedTab === 'BUDGET') {
      return renderTableBody(data.BUDGET, +kato !== OBLAST_KATO && isRegion(+kato), selectedTab);
    } else if (['MCB', 'EMPLOYMENT', 'LIVESTOCK'].includes(selectedTab)) {
      return renderTableBody(data[selectedTab], +kato !== OBLAST_KATO && isRegion(+kato), selectedTab);
    }
    return <tbody></tbody>;
  };

  useEffect(() => {
    if (DATA) {
      const SER = order.map((key: string) => {
        const item = DATA.SER?.find((item: any) => item.subType === key)
        if (item) {
          return {
            ...item,
            value: item.currentYearVal?.toLocaleString() || '-',
            increase: item.increaseVal || '-',
            place: item.additionalInfo?.place ? `${item.additionalInfo?.place}` : '-',
            icon: SERIcons[key] || null,
            name: keyToKeyInverted[key],
            add: key === 'HOUSE_COMMISSIONING' ? 'кв. м.' : '',
            ifoPlace: (kato && +kato === OBLAST_KATO ? item.additionalInfo?.IFOPlace : item.ifoPlace) || '-',
            ifo: item.ifo?.toString() || '-',
            link: links[key] || null,
          }
        }
      })

      const BUDGET = DATA.BUDGET?.map((item: any) => ({
        ...item,
        value: item.currentYearVal?.toLocaleString() || '-',
        increase: item.increaseVal || '-',
        place: item.additionalInfo?.place ? `${item.additionalInfo?.place}` : '-',
        icon: SERIcons[item.subType] || null,
        name: t('new-fields.' + item.subType),
        development: item.additionalInfo?.development || '-',
        ifoPlace: (kato && +kato === OBLAST_KATO ? item.additionalInfo?.IFOPlace : item.ifoPlace) || '-',
        ifo: item.ifo?.toString() || '-',
      }))

      const MCB = DATA.MCB?.map((item: any) => ({
        ...item,
        value: item.currentYearVal?.toLocaleString() || '-',
        increase: item.increaseVal || '-',
        add: item.subType === 'CURRENT_MSP' ? 'ед.' : '',
        icon: SERIcons[item.subType] || null,
        name: t('new-fields.' + item.subType),
        place: item.additionalInfo?.place ? `${item.additionalInfo?.place}` : '-',
        ifoPlace: (kato && +kato === OBLAST_KATO ? item.additionalInfo?.IFOPlace : item.ifoPlace) || '-',
        ifo: item.ifo?.toString() || '-',
      }))

      const EMPLOYMENT = DATA.EMPLOYMENT?.map((item: any) => ({
        ...item,
        value: item.currentYearVal?.toLocaleString() || '-',
        increase: item.increaseVal || '-',
        icon: SERIcons[item.subType] || null,
        name: t('new-fields.' + item.subType),
        add: item.subType === 'AVERAGE_MONTHLY_SALARY'
          ? 'тенге'
          : item.subType === 'NEW_WORKPLACE'
            ? 'ед.'
            : 'чел.',
        increaseAdd: item.subType === 'UNEMPLOYED' ? 'чел.' : null,
        place: item.additionalInfo?.place ? `${item.additionalInfo?.place}` : '-',
        ifoPlace: (kato && +kato === OBLAST_KATO ? item.additionalInfo?.IFOPlace : item.ifoPlace) || '-',
        ifo: item.ifo?.toString() || '-',
      }))

      const LIVESTOCK = DATA.LIVESTOCK?.map((item: any) => ({
        ...item,
        value: item.currentYearVal?.toLocaleString() || '-',
        increase: item.increaseVal || '-',
        add: ['MEAT', 'COW_MILK'].includes(item.subType)
          ? 'тонн'
          : item.subType === 'CHICKEN_EGG'
            ? 'тыс. шт.'
            : '',
        icon: SERIcons[item.subType] || null,
        name: t('new-fields.' + item.subType),
        place: item.additionalInfo?.place ? `${item.additionalInfo?.place}` : '-',
        ifoPlace: (kato && +kato === OBLAST_KATO ? item.additionalInfo?.IFOPlace : item.ifoPlace) || '-',
        ifo: item.ifo?.toString() || '-',
      }))

      setData({ SER, BUDGET, MCB, EMPLOYMENT, LIVESTOCK })
    }

  }, [DATA])

  if (!data) return null

  return (
    data && <SERContainer>
      {fullScreen.isOpen
        ? <BasicStatsItem
          style={{
            width: '100%',
            height: '100%',
            border: 'none',
            flex: 1,
          }}>
          <div className="title" style={{ display: 'flex', justifyContent: "flex-end" }}>
            <div className="close-btn" style={{ height: '100%', display: 'flex', width: 24, justifyContent: 'center', alignItems: 'center', cursor: 'pointer' }} onClick={() => setFullScreen({ isOpen: false, type: null })} >
              <DeleteX />
            </div>
          </div>
          <iframe title="iframe" src={links[fullScreen.type]} />
        </BasicStatsItem>
        : <>
          <div className="tabs">
            {tabs.map((tab: string) => (
              <div
                key={tab}
                className={selectedTab === tab ? 'tab active' : 'tab'}
                onClick={() => setSelectedTab(tab)}
              >
                {t('ser.' + tab)}
              </div>
            ))}
          </div>

          {
            selectedTab === tabs[1]
              ? <Operstab />
              : <SERTable>
                <table>
                  {getTHead()}
                  {getTBody()}
                </table>
              </SERTable>
          }


        </>}

    </SERContainer >
  )
}

export default SER