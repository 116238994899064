import React from 'react'
import { BasicStatsItem } from '../index.styles'

const Operstab = () => {
  const link = 'https://app.powerbi.com/view?r=eyJrIjoiZDI4ZmVjNWEtMDA3ZS00ZjQwLWE2NTAtNGZmMzZiMzQ5MzU1IiwidCI6ImU2MjQzNTFhLWViZGItNDUwMi1hNGEzLTRiZWI3OTYxODBlZiIsImMiOjl9'
  return (
    <BasicStatsItem
      style={{
        width: '100%',
        height: '100%',
        minHeight: '100%',
        flex: 1,  
        border: 'none',
      }}>
      <iframe title="Operstab iframe" src={link} />
    </BasicStatsItem>
  )
}

export default Operstab