import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { ToastContainer, toast } from 'react-toastify';
import { transformList } from './education.component';

import { getProjectList } from '../../requests/snp.request';
import { EducationContainer, EducationItem } from './index.styles';
import 'react-toastify/dist/ReactToastify.css';
import { IProject } from '../../interfaces/snp.interface';

import Documentation from './documentation.component';
import ZoomableImageModal from './zoomable-image.component';
import AdditionalFields from './additional-fields.component';
import { checkNonEmpty, checkVal, getLang } from '../../utils/helpers.utils';
import { EduMinus, EduPlus } from '../../assets/icons';
import { Text } from '../text.component';
import ImageGrid from './infrastructure-projects/image-grid-v2.component';

const Health = ({ kato, contentRef }: { kato: number, contentRef: any }) => {
  const { t } = useTranslation();
  const [projectList, setProjectList] = useState<IProject[]>([]);
  const [selectedImage, setSelectedImage] = useState<number | null>(null);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [currentImageList, setCurrentImageList] = useState<any[]>([]);
  const [isVisible, setIsVisible] = useState<Record<number, boolean>>({});
  const [objectName, setObjectName] = useState<string>('Фото');

  const openImageModal = (image: any, images: any, name: string) => {
    setIsOpen(true);
    !isNaN(image) ? setSelectedImage(image) : setSelectedImage(image.imageId)
    const imageList = (images.reduce((acc: any, item: any) => [...acc, ...item.imageIds], []));
    setCurrentImageList(imageList);
    setObjectName(name);
  }

  const closeImageModal = () => {
    setIsOpen(false);
    setSelectedImage(null);
    setCurrentImageList([]);
  }

  const handleOpenProject = (id: number) => {
    setIsVisible(prev => ({ ...prev, [id]: !prev[id] }));
  }

  useEffect(() => {
    getProjectList('health', kato)
      .then((res) => {
        const filtered = res.filter((project: any) => Object.keys(project).some((key) => !['id', 'kato'].includes(key) && checkVal(project[key]) && checkNonEmpty(project[key])));
        const obj = filtered.reduce((acc: any, item: any) => {
          acc[item.id] = res.length <= 3;
          return acc;
        }, {})
        setProjectList(transformList(filtered));
        setIsVisible(obj);
      })
      .catch((err) => {
        toast.error('Снп не выбрано');
      })
  }, [kato])

  if (projectList.length === 0) {
    return <p>{t('no-objects')}</p>
  }

  return (
    <>
      <EducationContainer ref={contentRef}>
        {projectList.length > 0 && projectList.map((project) =>
          <EducationItem key={project.id}>
            <div className="heading with-icon" onClick={() => handleOpenProject(project.id)}>
              <div className="education-icon">
                {isVisible[project.id] ? <EduMinus /> : <EduPlus />}
              </div>
              <Text color="#118f84" fontSize="0.875rem" fontWeight={500}>{project[`name${getLang()}`] || `${t('object-name')} ${project.id}`}</Text>
              <div className="year">
                <Text color="rgba(0, 1, 5, 0.5)" fontSize="0.875rem" fontWeight={400}>год постройки</Text>
                <Text color="rgba(0, 1, 5, 1)" fontSize="0.875rem" fontWeight={500}>{project.constructionYear}</Text>
              </div>
            </div>

            {isVisible[project.id] && <div className="body">
              <div className='items'>
                {
                  ['power', 'factInfo', 'factCondition'].some((key) => checkVal(project[key])) && <>
                    {
                      checkVal(project.power)
                      && <div className='item'>
                        <Text color="#818689" fontSize="0.875rem" fontWeight={400}>{t('form.project-power')}:</Text>
                        <Text color="##202224" fontSize="0.875rem" fontWeight={500}>{project.power}   {t('form.visitsPerDay')}</Text>
                      </div>
                    }
                    {
                      checkVal(project.factInfo)
                      && <div className='item'>
                        <Text color="#818689" fontSize="0.875rem" fontWeight={400}>{t('form.fact-power')}:</Text>
                        <Text color="##202224" fontSize="0.875rem" fontWeight={500}>{project.factInfo}   {t('form.visitsPerDay')}</Text>
                      </div>
                    }
                    {
                      checkVal(project.factCondition)
                      && <div className='item'>
                        <Text color="#818689" fontSize="0.875rem" fontWeight={400}>{t('form.factCondition')}:</Text>
                        <Text color="##202224" fontSize="0.875rem" fontWeight={500}> {t(`${project.factCondition.toLowerCase()}`)}</Text>
                      </div>
                    }
                  </>
                }

                {(project[`factLocation${getLang()}`]) && <div className='item'>
                  <Text color="#818689" fontSize="0.875rem" fontWeight={400}>{t('form.factPlace')}:</Text>
                  <Text color="##202224" fontSize="0.875rem" fontWeight={500}> {t(project[`factLocation${getLang()}`].toLowerCase())}</Text>
                </div>}
              </div>

              {(project[`repairRequired`] === 'true' || project[`repairRequired`] === true) &&
                <div className='text-block'>
                  <Documentation project={project} showFunds={false} />
                </div>
              }

              {checkVal(project.additionalFields) && Object.keys(project.additionalFields).filter((key: string) => !['ru', 'kz'].includes(key)).length > 0 && <AdditionalFields project={project} />}

              {project.images && <ImageGrid images={project.images} openImageModal={openImageModal} name={project[`name${getLang()}`] || `${t('object-name')} ${project.id}`} />}

            </div>
            }
          </EducationItem>
        )}
        <ToastContainer />
      </EducationContainer>
      {selectedImage && (
        <ZoomableImageModal
          isFileUpload={true}
          isOpen={isOpen}
          onClose={closeImageModal}
          selectedImage={selectedImage}
          setSelectedImage={setSelectedImage}
          currentImageList={currentImageList}
          objectName={objectName}
        />
      )}
    </>
  )
}

export default Health